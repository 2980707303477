import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  manufacturersArray: [],
  AddOrUpdateManufacturers: null,
  FetchListProcessCompleted: null,
  checkManufacturerCompleted: false,
  DeleteManufacturers: false,
}

export const ManufacturerList = createAsyncThunk(
  'administration/ManufacturerList',
  async (data) => {
    try {
      const response = await API.get(`/administration/manufacturer/${data.id}?page=${data.page}&size=10`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const ManufacturerCreate = createAsyncThunk(
  'administration/ManufacturerCreate',
  async (data) => {
    try {
      const response = await API.post(`/administration/manufacturer`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const ManufacturerDelete = createAsyncThunk(
  'administration/ManufacturerDelete',
  async (id) => {
    try {
      const response = await API.delete(`/administration/manufacturer/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const checkManufacturer = createAsyncThunk(
  'administration/checkManufacturer',
  async (data, state) => {
    try {
      state.checkManufacturerCompleted = false
      const response = await API.get(`/administration/check-manufacturer/${data}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const ManufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.AddOrUpdateManufacturers = null
      state.FetchListProcessCompleted = null
      state.checkManufacturerCompleted = false
    },
    resetState: (state) => {
      state.checkManufacturerCompleted = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ManufacturerList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.manufacturersArray = action.payload.data === null ? [] : action.payload.data.manufacturers
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.FetchListProcessCompleted = true
        state.DeleteManufacturers = false;
      } else if (action.payload.success === false && parseInt(action.payload.status) === 404) {
        state.manufacturersArray = []
        state.totalCount = 0
      }
    });
    builder.addCase(ManufacturerCreate.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.AddOrUpdateManufacturers = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        state.AddOrUpdateManufacturers = false
        ErrorBanner(action.payload.data.errors[0][0])
      }
    });
    builder.addCase(ManufacturerDelete.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.DeleteManufacturers = false;
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        state.DeleteManufacturers = true;
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(checkManufacturer.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.checkManufacturerCompleted = false
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.checkManufacturerCompleted = true
      }
    });
  },
})

export const { resetAllState } = ManufacturerSlice.actions;
export const { resetState } = ManufacturerSlice.actions

export default ManufacturerSlice.reducer