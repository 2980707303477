import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
// import { fetchCMSData } from "../../pages/super-admin/cms/cmsSlice";
import { useSelector } from "react-redux";
import TalkToExpert from "../talk-to-expert/TalkToExpert";

export default function Footer(props) {
  const token = localStorage.getItem("token")
  const loginSuccess = useSelector((state) => state.login.loginSuccess)
  const agreeTermsComplete = useSelector((state) => state.register.agreeTermsComplete)
  const cms = useSelector((state) => state.cms.cmsData)
  const [talkToExpertShow, setTalkToExpertShow] = useState(false);
  const TalkToExpertHandleHide = () => setTalkToExpertShow(false);

  const getCmsValue = (type, key) => {
    let found = cms?.find((cm) => cm.type === type && cm.key === key);
    return found !== undefined ? found.value : ''
  }

  const handleClick = (link) => {
    const baseUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/'
    window.open(baseUrl + link);
  };
  return (
    <div className="footer-main">
      {(token && token !== undefined) || (loginSuccess === true || agreeTermsComplete === true) ? (
        <div className="custom-container">
          <Row>
            <Col xs={5}>
              <p className="mb-0">© {new Date().getFullYear()} SolarConsult AG. Alle Rechte vorbehalten</p>
            </Col>
            <Col xs={7}>
              <Nav className="customer-footer-link">
                <Nav.Link onClick={() => handleClick('terms-of-use')} className="custom-link">
                  Nutzungsbedingungen
                </Nav.Link>
                <Nav.Link onClick={() => handleClick('legal-notice')} className="custom-link">
                  Impressum
                </Nav.Link>
                <Nav.Link onClick={() => handleClick('privacy-policy')} className="custom-link">
                  Datenschutz
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="custom-container">
          <Row className="justify-content-center align-items-center">
            <Col xs={4}>
              <div className="about-text" dangerouslySetInnerHTML={{ __html: getCmsValue('Footer', 'Text') }}>
              </div>
            </Col>
            <Col xs={5} className="d-flex justify-content-center">
              <div className="footer-quick-links">
                <Nav>
                  <Nav.Link onClick={() => handleClick('terms-of-use')} className="custom-link">
                    Nutzungsbedingungen
                  </Nav.Link>
                  <Nav.Link onClick={() => handleClick('legal-notice')} className="custom-link">
                    Impressum
                  </Nav.Link>
                  <Nav.Link onClick={() => handleClick('privacy-policy')} className="custom-link">
                    Datenschutz
                  </Nav.Link>
                </Nav>
              </div>
            </Col>
            <Col
              xs={3}
              className="justify-content-center d-flex justify-content-lg-end"
            >
              <button onClick={() => setTalkToExpertShow(true)} className="btn btn-primary text-start">
                Sprechen Sie mit <br />
                den Fachexperten
              </button>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col md={12}>
              <div className="copyright-text">
                © {new Date().getFullYear()} SolarConsult AG. Alle Rechte vorbehalten
              </div>
            </Col>
          </Row>
        </div>
      )}

      <TalkToExpert handleShow={talkToExpertShow} handleHide={TalkToExpertHandleHide} />
    </div>
  );
}
