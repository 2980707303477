import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../banner/banner";

const initialState = {
  status: null,
  message: null,
  errorsFromApiResponse: {},
  // loader: false,
  success: null,
  resetSuccess: null,
  resendmailcomplete: null,
  invalidToken: null
};

export const forgotpasswordAsync = createAsyncThunk(
  'forgotpassword/forgotpasswordAsync',
  async (data) => {
    try {
      const response = await API.post('/forget-password', data)
      return response
    } catch (err) {
      return err
    }
  }
)
export const resetpasswordAsync = createAsyncThunk(
  'forgotpassword/resetpasswordAsync',
  async (data) => {
    try {
      const response = await API.post('/reset-password', data)
      return response
    } catch (err) {
      return err
    }
  }
)


export const forgotpasswordSlice = createSlice({
  name: 'forgotpassword',
  initialState,
  reducers: {
    resetAllState: (state) => {
      // state.message = null
      state.status = null
      state.success = null
      state.errorsFromApiResponse = {}
      state.resendmailcomplete = null
      state.invalidToken = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(forgotpasswordAsync.fulfilled, (state, action) => {
      state.resendmailcomplete = true
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status
        state.success = action.payload.success
        state.message = action.payload.message
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 404) {
        state.success = action.payload.success
        state.message = action.payload.message
        state.errorsFromApiResponse.email = action.payload.message
        ErrorBanner(action.payload.message)
      } else {
        ErrorBanner(action.payload.message)
        state.success = action.payload.success
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(resetpasswordAsync.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status
        state.resetSuccess = action.payload.success
      } else if (parseInt(action.payload.status) === 403) {
        state.status = action.payload.status
        state.resetSuccess = action.payload.success
        ErrorBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 404) {
        state.status = action.payload.status
        state.invalidToken = true
        ErrorBanner(action.payload.message)
      } else {
        state.loader = false;
        state.resetSuccess = action.payload.success
        state.message = action.payload.message
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
  },
})
export const { resetAllState } = forgotpasswordSlice.actions;
export default forgotpasswordSlice.reducer