import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import RegisterModal from "../register-modal/RegisterModal";
import Logo from "../../assets/images/logo.svg";
import UserProfile from "../../assets/images/User-profile-dummy.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import LoginModal from "../login-modal/LoginModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectStatus, logoutAsync } from "../login-modal/LoginModalSlice";
import { fetchCMSData, getUserRole } from "../../pages/admin/cms/cmsSlice";
import TalkToExpert from "../talk-to-expert/TalkToExpert";
import { resetAllState, updateTourStatus } from "../login-modal/LoginModalSlice";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { unsavedChangesFunc } from "../../pages/administration/AdministrationSlice";
import { activePvSystemTour } from "../../pages/calculations/CalculationSlice";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation()
  const [hideMenu, setHideMenu] = useState()
  useEffect(() => { setHideMenu(location.pathname.includes("terms-of-use")) }, [location])

  const cms = useSelector((state) => state.cms.cmsData)
  const { rolesData } = useSelector((state) => state.cms)
  const [roles, setRoles] = useState({})
  const [show, setShow] = useState(false);
  const userData = useSelector((state) => state.login.loggedInUserInfo);
  // eslint-disable-next-line
  const [subscriptionLink, setSubscriptionLink] = useState(false);
  const pathName = window.location.pathname;
  const [registerShow, setRegisterShow] = useState(false);
  const [talkToExpertShow, setTalkToExpertShow] = useState(false);
  const status = useSelector(selectStatus);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // localStorage.setItem('UserForVerification', null)
  const UserForVerification = localStorage.getItem("UserForVerification") === undefined ? {} : JSON.parse(localStorage.getItem("UserForVerification"))
  const handleClose = () => {
    setShow(false)
    dispatch(resetAllState())
  };
  const RegisterhandleClose = () => setRegisterShow(false);
  const TalkToExpertHandleHide = () => setTalkToExpertShow(false);
  const handleLoginShow = () => setShow(true);
  const handleRegisterShow = () => {
    setShow(false);
    setRegisterShow(true);
  };
  const handleLogout = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(logoutAsync(user));
    setTimeout(() => {
      window.location = '/'
    }, 1000);
  };
  useEffect(() => {
    if (status && token) {
      setShow(false);
    }
    // dispatch(resetAllState());
    if (pathName === "/offer") {
      setSubscriptionLink(true);
    }
  }, [token, status, pathName]);

  useEffect(() => {
    dispatch(fetchCMSData());
  }, [dispatch]);

  useEffect(() => {
    if (userData && parseInt(userData.terms_conditions) === 1) {
      dispatch(getUserRole(userData.id));
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (Object.keys(rolesData).length > 0) { setRoles(rolesData) }
  }, [rolesData])

  const getCmsValue = (type, key) => {
    let found = cms?.find((cm) => cm.type === type && cm.key === key);
    return found !== undefined ? found.value : ''
  }

  const [DemoExpired, setDemoExpired] = useState(false)
  const demo_expired = useSelector((state) => state.login.demo_expired)

  useEffect(() => {
    if (demo_expired === null) {
      if (user !== null && user.role_id !== 1 && user.company.demo_untill !== null) {
        const today = new Date()
        const demoDate = new Date(user.company.demo_untill)
        if (demoDate < today && parseInt(userData.demo_enabled) === 1) {
          // navigate('/offer')
          setDemoExpired(true)
        } else if (demoDate < today) {
          setDemoExpired(true)
        }
      }
    } else if (demo_expired === true && parseInt(userData.demo_enabled) === 1) {
      // navigate('/offer')
      setDemoExpired(true)
    }
    // eslint-disable-next-line
  }, [demo_expired])

  const [demoUntill, setDemoUntill] = useState()
  const [daysLeft, setDaysLeft] = useState()
  useEffect(() => {
    if (token && token !== undefined && user !== null) {
      const dateFromDB = new Date(user.company.demo_untill)
      const monthDigit = dateFromDB.getMonth() + 1
      const finalMonth = monthDigit < 10 ? '0' + monthDigit : monthDigit
      setDemoUntill(dateFromDB.getDate() + '.' + finalMonth + '.' + dateFromDB.getFullYear())
      const todayDate = new Date()
      const Difference_In_Time = dateFromDB.getTime() - todayDate.getTime()
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
      const days = (Math.round(parseFloat(Difference_In_Days)) > 0 ? Math.round(parseFloat(Difference_In_Days)) : 0)
      setDaysLeft(days + ' Tage')
    }
  }, [token, user])

  const unsavedChanges = useSelector((state) => state.administration.unsavedChanges)

  // Handle the beforeunload event to show a confirmation dialog
  const handleBeforeUnload = (event) => {
    if (unsavedChanges) {
      event.preventDefault();
      event.returnValue = ''; // This is required for Chrome
    }
  };

  // Add event listeners when the component mounts
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    // eslint-disable-next-line
  }, [unsavedChanges]);

  const unsavedChangesConfirmmationBox = (nextpage) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='unsaved-changes-alert'>
            <h1>Warnung</h1>
            <p>Es gibt ungesicherte Informationen.</p>
            <p>Sind Sie sicher, dass Sie die Seite verlassen wollen? Alle Änderungen gehen verloren.</p>
            <div className="unsaved-changes-actions d-flex justify-content-between">
              <button className="btn btn-primary-outline" onClick={() => {
                dispatch(unsavedChangesFunc({ value: false }))
                navigate(nextpage)
                onClose()
              }}>Seite verlassen</button>
              <button className="btn btn-primary" onClick={onClose}>
                Zurück zur Seite
              </button>
            </div>
          </div>
        );
      }
    })
  }

  const unsavedChangesConfirmmationBoxLogout = () => {
    if (unsavedChanges) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='unsaved-changes-alert'>
              <h1>Warnung</h1>
              <p>Es gibt ungesicherte Informationen.</p>
              <p>Sind Sie sicher, dass Sie die Seite verlassen wollen? Alle Änderungen gehen verloren.</p>
              <div className="unsaved-changes-actions d-flex justify-content-between">
                <button className="btn btn-primary-outline" onClick={() => {
                  dispatch(unsavedChangesFunc({ value: false }))
                  handleLogout()
                  onClose()
                }}>Seite verlassen</button>
                <button className="btn btn-primary" onClick={onClose}>
                  Zurück zur Seite
                </button>
              </div>
            </div>
          );
        }
      })
    } else {
      handleLogout()
    }
  }

  // useEffect(() => {
  //   if (parseInt(roles.subscription_enabled) === 1 && window.location.pathname === '/offer') {
  //     navigate('/')
  //   }
  // }, [roles, navigate])

  const [activeSubscriptionCount, setActiveSubscriptionCount] = useState(0)
  const loggedInUserSubscriptionInfo = useSelector((state) => state.login.loggedInUserSubscriptionInfo)
  useEffect(() => {
    loggedInUserSubscriptionInfo?.map((subscription) => {
      if (subscription.status === 'Aktiv' || subscription.status === 'Gekündigt') { setActiveSubscriptionCount(1) }
      return true
    })
  }, [loggedInUserSubscriptionInfo])

  // tour management
  const [showTour, setShowTour] = useState(false);
  const tour_enabled = localStorage.getItem("tour_enabled");
  const userLoginCount = localStorage.getItem("userLoginCount");
  const { showPvSystemTour } = useSelector((state) => state.calculation)
  useEffect(() => {
    if (user && user.role_id !== 1) {
      if (tour_enabled === "0") {
        setTimeout(() => {
          setShowTour(true)
        }, 3000);
      } else if (tour_enabled === "2") {
        setTimeout(() => {
          setShowTour(true)
        }, 1600);
      } else if (userLoginCount === "2") {
        setTimeout(() => {
          setShowTour(true)
        }, 5000);
      }
    }
    if (showPvSystemTour === true) {
      setShowTour(true)
    }
  }, [user, showPvSystemTour])
  return (
    <>
      <div className="header-main">
        {token && token !== undefined && activeSubscriptionCount === 1 ? (
          <div className="header-top">
            <span dangerouslySetInnerHTML={{ __html: getCmsValue('Banner-loggedin', 'Text') }} />
          </div>
        ) : token && token !== undefined && DemoExpired === true ? (
          <div className="header-top">
            {daysLeft === "0 Tage" ? <span dangerouslySetInnerHTML={{ __html: `***DEMO*** PVfin ist am ${demoUntill} abgelaufen.` }} /> : <span dangerouslySetInnerHTML={{ __html: getCmsValue('Banner-demo', 'Text').replace('[VariableDemoEndDate]', demoUntill).replace('[VariableDaysUntilDemoEnds]', daysLeft) }} />}
          </div>
        ) : token && token !== undefined && DemoExpired === false ? (
          <div className="header-top">
            {daysLeft === "0 Tage" ? <span dangerouslySetInnerHTML={{ __html: `***DEMO*** PVfin ist am ${demoUntill} abgelaufen.` }} /> : <span dangerouslySetInnerHTML={{ __html: getCmsValue('Banner-demo', 'Text').replace('[VariableDemoEndDate]', demoUntill).replace('[VariableDaysUntilDemoEnds]', daysLeft) }} />}
          </div>
        ) : (
          <div className="header-top" onClick={() => setRegisterShow(true)}>
            <span dangerouslySetInnerHTML={{ __html: getCmsValue('Banner-default', 'Text') }} />
          </div>
        )}
        <ToastContainer autoClose={3000} />
        <Navbar collapseOnSelect expand="lg">
          {token && token !== undefined ? (
            <div className="custom-container">
              <div className="w-100">
                <div className="toggle-header">
                  <Navbar.Brand onClick={() => {
                    unsavedChanges === true ?
                      unsavedChangesConfirmmationBox('/organisation') :
                      navigate('/')
                  }}>
                    <img src={Logo} alt="" />
                  </Navbar.Brand>
                  <a
                    className="btn btn-primary-outline ms-3"
                    target="_blank"
                    href={`https://pvfin.de`}
                    title={"https://pvfin.de"}
                  >
                    Alte Version
                  </a>
                  <Nav className="me-auto"></Nav>
                  <Nav>
                    {parseInt(user.subscription_id) === 0 || parseInt(roles.demo_enabled) === 1 ? (
                      <Button
                        onClick={() => navigate('/manage-subscription?tab=aboeinrichten')}
                        className="btn btn-primary w-100"
                      >
                        Vollversion erwerben
                      </Button>
                    ) : null}
                    {(DemoExpired === false || activeSubscriptionCount === 1) && parseInt(roles.user_enabled) === 1 ?
                      (unsavedChanges === true ?
                        <>
                          <Nav.Link onClick={() => unsavedChangesConfirmmationBox('/customers')} className={window.location.pathname === '/customers' ? "custom-link-active" : "custom-link"}>
                            Kunden
                          </Nav.Link>
                          <Nav.Link onClick={() => unsavedChangesConfirmmationBox('/calculation')} className={window.location.pathname === '/calculation' ? "custom-link-active" : "custom-link"}>
                            Berechnungen
                          </Nav.Link>
                        </>
                        :
                        <>
                          <div className="position-relative tour-main">
                            <Nav.Link to="/customers" as={Link} className={window.location.pathname === '/customers' ? "custom-link-active" : "custom-link"}>
                              Kunden
                            </Nav.Link>
                            {
                              showTour && tour_enabled === "0" && window.location.pathname.includes('add-customer') === false ? <div className="tour-content"><div className="tour-open">
                                <div className="tour-open-tringle"></div>
                                <div className="tour-open-header d-flex align-items-center justify-content-between">
                                  <h6>Ersten Kunden anlegen</h6>
                                  <Button className="btn btn-primary ms-0" onClick={() => {
                                    setShowTour(false)
                                    dispatch(updateTourStatus(1));
                                  }}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0.377644 0.377644C0.49705 0.257936 0.638899 0.16296 0.795067 0.0981579C0.951234 0.0333554 1.11865 0 1.28773 0C1.45681 0 1.62423 0.0333554 1.7804 0.0981579C1.93656 0.16296 2.07841 0.257936 2.19782 0.377644L9.00034 7.18273L15.8029 0.377644C15.9224 0.25813 16.0643 0.163325 16.2204 0.0986446C16.3766 0.0339638 16.5439 0.000673011 16.7129 0.000673011C16.882 0.000673011 17.0493 0.0339638 17.2055 0.0986446C17.3616 0.163325 17.5035 0.25813 17.623 0.377644C17.7425 0.497158 17.8373 0.639042 17.902 0.795195C17.9667 0.951348 18 1.11871 18 1.28773C18 1.45675 17.9667 1.62411 17.902 1.78027C17.8373 1.93642 17.7425 2.0783 17.623 2.19782L10.8179 9.00034L17.623 15.8029C17.7425 15.9224 17.8373 16.0643 17.902 16.2204C17.9667 16.3766 18 16.5439 18 16.7129C18 16.882 17.9667 17.0493 17.902 17.2055C17.8373 17.3616 17.7425 17.5035 17.623 17.623C17.5035 17.7425 17.3616 17.8373 17.2055 17.902C17.0493 17.9667 16.882 18 16.7129 18C16.5439 18 16.3766 17.9667 16.2204 17.902C16.0643 17.8373 15.9224 17.7425 15.8029 17.623L9.00034 10.8179L2.19782 17.623C2.0783 17.7425 1.93642 17.8373 1.78027 17.902C1.62411 17.9667 1.45675 18 1.28773 18C1.11871 18 0.951348 17.9667 0.795195 17.902C0.639042 17.8373 0.497158 17.7425 0.377644 17.623C0.25813 17.5035 0.163325 17.3616 0.0986446 17.2055C0.0339638 17.0493 0.000673011 16.882 0.000673011 16.7129C0.000673011 16.5439 0.0339638 16.3766 0.0986446 16.2204C0.163325 16.0643 0.25813 15.9224 0.377644 15.8029L7.18273 9.00034L0.377644 2.19782C0.257936 2.07841 0.16296 1.93656 0.0981579 1.7804C0.0333554 1.62423 0 1.45681 0 1.28773C0 1.11865 0.0333554 0.951234 0.0981579 0.795067C0.16296 0.638899 0.257936 0.49705 0.377644 0.377644Z" fill="#9AC56A" />
                                    </svg>
                                  </Button>
                                </div>
                                <div className="tour-open-body">
                                  <p>Um direkt mit PVfin zu starten, können Sie hier ganz einfach einen neuen Kunden anlegen. Im Anschluss können Sie bereits Ihre erste Berechnung erstellen.</p>
                                </div>
                                <div className="tour-open-footer d-flex justify-content-end">
                                  <Button
                                    onClick={() => {
                                      setShowTour(false)
                                      dispatch(updateTourStatus(1));
                                      navigate('/add-customer')
                                    }}
                                    className="btn btn-primary">
                                    Kunden anlegen
                                  </Button>
                                </div>
                              </div>
                              </div>
                                : null
                            }
                          </div>
                          <Nav.Link
                            to="/calculation"
                            as={Link}
                            className={window.location.pathname === '/calculation' ? "custom-link-active" : "custom-link"}
                          >
                            Berechnungen
                          </Nav.Link>
                        </>
                      )
                      : null}

                    {parseInt(roles.admin_enabled) === 1 && user.role_id === 1 ?
                      <Nav.Link href="#" >
                        <Dropdown className="administration-menu">
                          <Dropdown.Toggle id="dropdown-basic">
                            Administration
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/organisation') : navigate('/organisation')
                            }} className={window.location.pathname === '/organisation' ? "custom-link-active" : ""}>
                              Organisation
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/users') : navigate('/users')
                            }} className={window.location.pathname === '/users' ? "custom-link-active" : ""}>
                              Benutzer
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/standard-overview-list') : navigate('/standard-overview-list')
                            }} className={window.location.pathname === '/standard-overview-list' ? "custom-link-active" : ""}>
                              Vorgaben
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/subscriptions') : navigate('/subscriptions')
                            }} className={window.location.pathname === '/subscriptions' ? "custom-link-active" : ""}>
                              Abonnements
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/cms') : navigate('/cms')
                            }} className={window.location.pathname === '/cms' ? "custom-link-active" : ""}>
                              CMS
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav.Link>
                      : null}

                    <Dropdown className="user-profile-dropdown tour-create-storage-systems tour-main">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img
                          src={
                            userData?.profile_picture === "" ||
                              userData?.profile_picture === null
                              ? UserProfile
                              : userData?.profile_picture
                          }
                          alt=""
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="user-profile-area">
                          <div className="user-profile-pic-inner">
                            <img
                              src={
                                userData?.profile_picture === "" ||
                                  userData?.profile_picture === null
                                  ? UserProfile
                                  : userData?.profile_picture
                              }
                              alt=""
                            />
                            <div className="user-profile-pic-inner-text">
                              <h3>
                                {userData?.first_name +
                                  " " +
                                  userData?.last_name}
                              </h3>
                              <Nav.Link onClick={() => {
                                unsavedChanges === true ?
                                  unsavedChangesConfirmmationBox('/personal-data') :
                                  navigate('/personal-data')
                              }} className={window.location.pathname === '/personal-data' ? "ms-0 pb-0 mt-0 profile-text-align custom-link-active" : "pb-0 ms-0 mt-0 profile-text-align"}>
                                Profil verwalten
                              </Nav.Link>
                            </div>
                          </div>
                        </div>

                        {(DemoExpired === false || activeSubscriptionCount === 1) && parseInt(roles.manager_enabled) === 1 ?
                          <>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/employees') : navigate('/employees')
                            }} className={window.location.pathname === '/employees' ? "custom-link-active" : ""}>
                              Mitarbeitende
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                              unsavedChanges === true ?
                                unsavedChangesConfirmmationBox('/manufacturers') : navigate('/manufacturers')
                            }} className={window.location.pathname === '/manufacturers' ? "custom-link-active" : ""}>
                              Hersteller
                            </Dropdown.Item>
                          </>
                          : null}
                        {(DemoExpired === false || activeSubscriptionCount === 1) && (parseInt(roles.manager_enabled) === 1 || (parseInt(roles.manager_enabled) === 0 && parseInt(roles.admin_enabled) === 1)) ?
                          <>
                            <div className={(parseInt(roles.manager_enabled) === 0 && parseInt(roles.admin_enabled) === 1) ? "user-profile-link-last" : ""}>
                              <Dropdown.Item onClick={() => {
                                unsavedChanges === true ?
                                  unsavedChangesConfirmmationBox('/consumption-profiles') : navigate('/consumption-profiles')
                              }} className={window.location.pathname === '/consumption-profiles' ? "custom-link-active" : ""}>Verbrauchsprofile</Dropdown.Item>
                            </div>
                          </>
                          : null}
                        {(DemoExpired === false || activeSubscriptionCount === 1) && parseInt(roles.manager_enabled) === 1 ?
                          <>
                            <div className="user-profile-link-last">
                              <Dropdown.Item onClick={() => {
                                unsavedChanges === true ?
                                  unsavedChangesConfirmmationBox('/contact') : navigate('/contact')
                              }} className={window.location.pathname === '/contact' ? "custom-link-active" : ""}>Kontakt</Dropdown.Item>
                            </div>
                          </>
                          : null}
                        <Dropdown.Item
                          onClick={unsavedChangesConfirmmationBoxLogout}
                          className="logout-link"
                        >
                          Abmelden
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      {
                        showTour && tour_enabled === "2" && showPvSystemTour ? <div className="tour-content">
                          <div className="tour-open">
                            <div className="tour-open-tringle"></div>
                            <div className="tour-open-header d-flex align-items-center justify-content-between">
                              <h6>Speichersysteme anlegen</h6>
                              <Button className="btn btn-primary ms-0" onClick={() => {
                                setShowTour(false)
                                dispatch(activePvSystemTour(false))
                                dispatch(updateTourStatus(3));
                              }}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.377644 0.377644C0.49705 0.257936 0.638899 0.16296 0.795067 0.0981579C0.951234 0.0333554 1.11865 0 1.28773 0C1.45681 0 1.62423 0.0333554 1.7804 0.0981579C1.93656 0.16296 2.07841 0.257936 2.19782 0.377644L9.00034 7.18273L15.8029 0.377644C15.9224 0.25813 16.0643 0.163325 16.2204 0.0986446C16.3766 0.0339638 16.5439 0.000673011 16.7129 0.000673011C16.882 0.000673011 17.0493 0.0339638 17.2055 0.0986446C17.3616 0.163325 17.5035 0.25813 17.623 0.377644C17.7425 0.497158 17.8373 0.639042 17.902 0.795195C17.9667 0.951348 18 1.11871 18 1.28773C18 1.45675 17.9667 1.62411 17.902 1.78027C17.8373 1.93642 17.7425 2.0783 17.623 2.19782L10.8179 9.00034L17.623 15.8029C17.7425 15.9224 17.8373 16.0643 17.902 16.2204C17.9667 16.3766 18 16.5439 18 16.7129C18 16.882 17.9667 17.0493 17.902 17.2055C17.8373 17.3616 17.7425 17.5035 17.623 17.623C17.5035 17.7425 17.3616 17.8373 17.2055 17.902C17.0493 17.9667 16.882 18 16.7129 18C16.5439 18 16.3766 17.9667 16.2204 17.902C16.0643 17.8373 15.9224 17.7425 15.8029 17.623L9.00034 10.8179L2.19782 17.623C2.0783 17.7425 1.93642 17.8373 1.78027 17.902C1.62411 17.9667 1.45675 18 1.28773 18C1.11871 18 0.951348 17.9667 0.795195 17.902C0.639042 17.8373 0.497158 17.7425 0.377644 17.623C0.25813 17.5035 0.163325 17.3616 0.0986446 17.2055C0.0339638 17.0493 0.000673011 16.882 0.000673011 16.7129C0.000673011 16.5439 0.0339638 16.3766 0.0986446 16.2204C0.163325 16.0643 0.25813 15.9224 0.377644 15.8029L7.18273 9.00034L0.377644 2.19782C0.257936 2.07841 0.16296 1.93656 0.0981579 1.7804C0.0333554 1.62423 0 1.45681 0 1.28773C0 1.11865 0.0333554 0.951234 0.0981579 0.795067C0.16296 0.638899 0.257936 0.49705 0.377644 0.377644Z" fill="#9AC56A" />
                                </svg>
                              </Button>
                            </div>
                            <div className="tour-open-body">
                              <p className="mb-0">Unter Speichersysteme kann der Manager Ihrer Organisation verschiedene Speichersysteme anlegen und verwalten, um zentrale Werte, wie den Systemwirkungsgrad oder die Lebensdauer des Akkus, festzulegen.</p>
                            </div>
                          </div>
                        </div> : null}
                      {
                        showTour && userLoginCount === "2" ? <div className="tour-content">
                          <div className="tour-open">
                            <div className="tour-open-tringle"></div>
                            <div className="tour-open-header d-flex align-items-center justify-content-between">
                              <h6>Administration</h6>
                              <Button className="btn btn-primary ms-0" onClick={() => {
                                setShowTour(false)
                                dispatch(updateTourStatus(4));
                              }}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.377644 0.377644C0.49705 0.257936 0.638899 0.16296 0.795067 0.0981579C0.951234 0.0333554 1.11865 0 1.28773 0C1.45681 0 1.62423 0.0333554 1.7804 0.0981579C1.93656 0.16296 2.07841 0.257936 2.19782 0.377644L9.00034 7.18273L15.8029 0.377644C15.9224 0.25813 16.0643 0.163325 16.2204 0.0986446C16.3766 0.0339638 16.5439 0.000673011 16.7129 0.000673011C16.882 0.000673011 17.0493 0.0339638 17.2055 0.0986446C17.3616 0.163325 17.5035 0.25813 17.623 0.377644C17.7425 0.497158 17.8373 0.639042 17.902 0.795195C17.9667 0.951348 18 1.11871 18 1.28773C18 1.45675 17.9667 1.62411 17.902 1.78027C17.8373 1.93642 17.7425 2.0783 17.623 2.19782L10.8179 9.00034L17.623 15.8029C17.7425 15.9224 17.8373 16.0643 17.902 16.2204C17.9667 16.3766 18 16.5439 18 16.7129C18 16.882 17.9667 17.0493 17.902 17.2055C17.8373 17.3616 17.7425 17.5035 17.623 17.623C17.5035 17.7425 17.3616 17.8373 17.2055 17.902C17.0493 17.9667 16.882 18 16.7129 18C16.5439 18 16.3766 17.9667 16.2204 17.902C16.0643 17.8373 15.9224 17.7425 15.8029 17.623L9.00034 10.8179L2.19782 17.623C2.0783 17.7425 1.93642 17.8373 1.78027 17.902C1.62411 17.9667 1.45675 18 1.28773 18C1.11871 18 0.951348 17.9667 0.795195 17.902C0.639042 17.8373 0.497158 17.7425 0.377644 17.623C0.25813 17.5035 0.163325 17.3616 0.0986446 17.2055C0.0339638 17.0493 0.000673011 16.882 0.000673011 16.7129C0.000673011 16.5439 0.0339638 16.3766 0.0986446 16.2204C0.163325 16.0643 0.25813 15.9224 0.377644 15.8029L7.18273 9.00034L0.377644 2.19782C0.257936 2.07841 0.16296 1.93656 0.0981579 1.7804C0.0333554 1.62423 0 1.45681 0 1.28773C0 1.11865 0.0333554 0.951234 0.0981579 0.795067C0.16296 0.638899 0.257936 0.49705 0.377644 0.377644Z" fill="#9AC56A" />
                                </svg>
                              </Button>
                            </div>
                            <div className="tour-open-body">
                              <p className="mb-0">Klicken Sie auf Ihr Profil für weitere Einstellungsmöglichkeiten wie das</p>
                              <ul>
                                <li>Ändern Ihrer persönlichen Daten</li>
                                <li>Hochladen eines Logo</li>
                                <li>Individualisieren der Reports mit den Firmenfarben Ihrer Organisation</li>
                                <li>Abschließen eines Abonnements</li>
                                <li>Hinzufügen neuer Mitarbeiter</li>
                                <li>Verwalten Ihrer Speichersysteme und vieles mehr.</li>
                              </ul>
                            </div>
                          </div>
                        </div> : null}
                    </Dropdown>
                  </Nav>
                  {/* </Navbar.Collapse> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="custom-container">
              <div className="w-100">
                <div className="toggle-header">
                  <Navbar.Brand onClick={() => navigate('/')}>
                    <img src={Logo} alt="" />
                  </Navbar.Brand>
                  <a
                    className="btn btn-primary-outline ms-3"
                    target="_blank"
                    href={`https://pvfin.de`}
                    title={"https://pvfin.de"}
                  >
                    Alte Version
                  </a>
                  {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                  {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
                  <Nav className="me-auto"></Nav>
                  {hideMenu === true && UserForVerification && parseInt(UserForVerification.terms_conditions) === 0 ? null :
                    <Nav>
                      <Nav.Link href="" className="custom-link" onClick={handleLoginShow}>
                        Anmelden
                      </Nav.Link>
                      <Button
                        className="btn btn-primary-outline"
                        onClick={() => {
                          setTalkToExpertShow(true)
                        }}
                      >
                        Sprechen Sie mit Experten
                      </Button>
                      <Button
                        className={window.location.pathname === "/registration-success" ? "btn btn-primary ms-2 d-none" : "btn btn-primary ms-2"}
                        onClick={() => setRegisterShow(true)}
                      >
                        Registrieren
                      </Button>
                    </Nav>
                  }
                  {/* </Navbar.Collapse> */}
                </div>
              </div>
            </div>
          )}
        </Navbar>

        {/* Login Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="login-box-custom-width"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <LoginModal handleRegisterShow={handleRegisterShow} handleLoginClose={handleClose} />
          </Modal.Body>
        </Modal>
        <TalkToExpert handleShow={talkToExpertShow} handleHide={TalkToExpertHandleHide} />
        <RegisterModal registerShow={registerShow} RegisterhandleClose={RegisterhandleClose} />
      </div>
    </>
  );
};

export default Header;
