import { configureStore } from '@reduxjs/toolkit'

import LoginModalSlice from '../components/login-modal/LoginModalSlice';
import RegisterModalSlice from '../components/register-modal/RegisterModalSlice';
import ForgotPasswordSlice from '../components/forgot-password/ForgotPasswordSlice';
import OrganisationSlice from '../pages/admin/organisation/OrganisationSlice';
import customerSlice from '../pages/customers/CustomersSlice';
import AdministrationSlice from '../pages/administration/AdministrationSlice';
import cmsSlice from '../pages/admin/cms/cmsSlice';
import calculationSlice from '../pages/calculations/CalculationSlice';
import UserSlice from '../pages/users/UserSlice';
import EmployeeSlice from '../pages/employees/EmployeeSlice';
import ConsumptionSlice from '../pages/consumption/ConsumptionSlice';
import ManufacturerSlice from '../pages/manufacturers/ManufacturerSlice';
import HeaderSlice from '../components/header/HeaderSlice';
import StandardSlice from '../pages/admin/defaults/StandardSlice';
import SubscriptionSlice from '../pages/admin/subscription/SubscriptionSlice';

export default configureStore({
  reducer: {
    login: LoginModalSlice,
    register: RegisterModalSlice,
    forgotpassword: ForgotPasswordSlice,
    organisations: OrganisationSlice,
    customers: customerSlice,
    administration: AdministrationSlice,
    cms: cmsSlice,
    calculation: calculationSlice,
    employee: EmployeeSlice,
    user: UserSlice,
    consumption: ConsumptionSlice,
    manufacturer: ManufacturerSlice,
    header: HeaderSlice,
    standard: StandardSlice,
    subscription: SubscriptionSlice
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})