import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
// import { ToastContainer } from 'react-toastify';
// import { checkTokenIsValid } from './components/login-modal/LoginModalSlice';
import { Layout } from "./layout";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import {
  adduserFromLocalStorage,
  logoutAsync,
} from "./components/login-modal/LoginModalSlice";
import { useJsApiLoader } from "@react-google-maps/api";
import { Col, Modal } from "react-bootstrap";

function App() {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const userData = useSelector((state) => state.login.loggedInUserInfo);
  const [libraries] = useState(["places"]);
  const [noticeModel, setnoticeModel] = useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: "DE",
    libraries,
  });

  useEffect(() => {
    dispatch(adduserFromLocalStorage());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem("token") === null && (localStorage.getItem("isSiteVisited") === null || localStorage.getItem("isSiteVisited") === false)) {
      setnoticeModel(true);
    }
    // console.log(userData !== null)
    // console.log(localStorage.getItem('UserForVerification') === null)
    // console.log(localStorage.getItem('login_expiration_time') !== null)
    if (
      userData !== null &&
      localStorage.getItem("UserForVerification") === null &&
      localStorage.getItem("login_expiration_time") !== null
    ) {
      // console.log('Auto logout after 24 hours working...')
      const CurrentDateTime = new Date();
      // CurrentDateTime.setHours(CurrentDateTime.getHours() + 24)
      const LoginExpirationTime = new Date(
        localStorage.getItem("login_expiration_time")
      );
      if (CurrentDateTime > LoginExpirationTime) {
        dispatch(logoutAsync(userData));
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      }
    }
  }, []);

  if (!isLoaded)
    return (
      <div className="container">
        <div className="row">
          <div className="col" style={{ marginTop: `20%` }}>
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="App">
      <>
        <Modal
          show={noticeModel}
          onHide={() => setnoticeModel(false)}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="notice-modal"
        >
          <Modal.Body>
            <>
              <Col lg={12} >
                <h1>Hinweis</h1>
                <p>
                  Wir haben eine neue Version von PVfin entwickelt! <br></br> Neben einer
                  komplett überarbeiteten Oberfläche können nun mehrere
                  Verbrauchsprofile und Modulfelder angelegt werden. Auch die
                  Eingabe von einer individuellen Einspeisevergütung sowie
                  präzisere Ertragsprognosen sind Bestandteil der neuen Version.<br></br>
                  Klicken Sie auf <strong>Weiter</strong> und probieren Sie es
                  direkt aus.
                </p>
              </Col>
            </>

          </Modal.Body>
          <div className="notice-footer d-flex justify-content-between">

            <a
              className="btn btn-primary-outline "
              target="_blank"
              href={`https://pvfin.de`}
              title={"https://pvfin.de"}
              onClick={() => {
                setnoticeModel(false)
                localStorage.setItem("isSiteVisited", true)
              }}
            >
              Zur alten Version
            </a>
            <button className="btn btn-primary" onClick={() => {
              localStorage.setItem("isSiteVisited", true)
              setnoticeModel(false)
            }}>
              Weiter
            </button>
          </div>
        </Modal>
      </>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="container">
              <div className="row">
                <div className="col" style={{ marginTop: `20%` }}>
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          {/* <Routes>
            { CutomerRoutes.map((route, index) => {
              return (
                route.element && (
                  <Route key={index} exact={route.exact} path={route.path} name={route.name} element={<route.element />}/>  
                )
              )          
            })}
          </Routes> */}
          <Layout />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
