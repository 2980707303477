import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../../services/api';
import { ErrorBanner, SuccessBanner } from "../../../components/banner/banner";

const initialState = {
  modules: [],
  propertyDataForEdit: {},
  updatePropertyDefaultCompleted: null,
  propertyDataList: null,
  propertyDataListComplete: null,
  propertyIsDropdown: null,
  addPropertyDefaultCompleted: null,
}

export const getCalculationDefaults = createAsyncThunk(
  'standard/getCalculationDefaults',
  async () => {
    try {
      const response = await API.get(`/admin/get-calculation-defaults`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const goToEdit = createAsyncThunk(
  'standard/goToEdit',
  async (data) => { return data }
)

export const updatePropertyDefault = createAsyncThunk(
  'standard/updatePropertyDefault',
  async (data) => {
    try {
      const response = await API.post(`/admin/update-calculation-default/${data.module_id}/${data.property_name}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const getPropertyData = createAsyncThunk(
  'standard/getPropertyData',
  async (data) => {
    // console.log("46",data)
    try {
      const response = await API.get(`/admin/edit-calculation-defaults/${data.property_name}/${data.is_dropdown}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const addPropertyDefault = createAsyncThunk(
  'standard/addPropertyDefault',
  async (data) => {
    try {
      const response = await API.post(`/admin/add-calculation-custom-default`, data)
      return response
    } catch (err) {
      return err
    }
  }
)


export const StandardSlice = createSlice({
  name: 'standard',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.updatePropertyDefaultCompleted = null
      state.propertyDataList = null
      state.propertyDataListComplete = false
      state.addPropertyDefaultCompleted = null
    },
    resetPropertyDataForEdit: (state) => {
      state.propertyDataForEdit = {}
      state.propertyDataList = null
      state.propertyDataListComplete = false
    },
    resetFormState: (state) => {
      state.addPropertyDefaultCompleted = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalculationDefaults.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.modules = action.payload.data === null ? [] : action.payload.data.results
      }
    });
    builder.addCase(goToEdit.fulfilled, (state, action) => {
      state.propertyDataForEdit = action.payload
    });
    builder.addCase(updatePropertyDefault.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.updatePropertyDefaultCompleted = true
        state.propertyDataList = {}
        SuccessBanner(action.payload.message)
      } else {
        state.updatePropertyDefaultCompleted = false
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(getPropertyData.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.propertyIsDropdown = action.payload.data.type.is_dropdown;
        state.propertyDataListComplete = true
        if (action.payload.data.type.is_dropdown === 1) {
          state.propertyDataList = action.payload.data.master_table;
        }
      } else {
        state.propertyDataListComplete = false
      }
    });

    builder.addCase(addPropertyDefault.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.addPropertyDefaultCompleted = true
        SuccessBanner(action.payload.message)
      } else {
        state.addPropertyDefaultCompleted = false
        ErrorBanner(action.payload.message)
      }
    });
  },
})

export const { resetAllState, resetPropertyDataForEdit, propertyDataList, propertyIsDropdown, resetFormState } = StandardSlice.actions;
export default StandardSlice.reducer