import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { handlelocalStorageClear } from '../../basic';

const initialState = {
  status: null,
  success: null,
  token: null,
  message: "",
  alreadyLoggedIn: null,
  logoutSuccess: null,
  loggedInUserInfo: null,
  loggedInUserSubscriptionInfo: null,
  loginSuccess: null,
  agree_TaC: null,
  demo_expired: null
};

export const loginAsync = createAsyncThunk(
  'login/postLogin',
  async (data) => {
    try {
      if (data.closePreviousSession === 'YES') {
        // eslint-disable-next-line
        const responseLogout = await API.post(`/logout/${data.tempUserid}`, data, {
          headers: {
            "Authorization": "Bearer " + data.tempToken
          }
        });
      }
      const response = await API.post('/login', data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const logoutAsync = createAsyncThunk(
  'login/postLogout',
  async (data) => {
    try {
      // console.log('Checking on logout :- ', data.access_token)
      const response = await API.post(`/logout/${data.id}`, {}, {
        headers: {
          "Authorization": "Bearer " + data.access_token
        }
      });
      // console.log(response)
      return response
    } catch (err) {
      return err;
    }
  }
);

export const checkTokenIsValid = createAsyncThunk(
  'login/checkTokenIsValid',
  async () => {
    try {
      const response = await API.post(`/isValid`, {});
      return response
    } catch (err) {
      return err;
    }
  }
);

export const updateTourStatus = createAsyncThunk(
  'login/updateTourStatus',
  async (action) => {
    try {
      const response = await API.post(`/updateTourStatus`, { action: action });
      return response
    } catch (err) {
      return err;
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.status = null
      state.success = null
      // state.message = ""
      state.loginSuccess = null
      state.agree_TaC = null
      state.alreadyLoggedIn = null
    },
    resetToken: (state) => {
      state.token = null
    },
    adduserFromLocalStorage: (state) => {
      state.loggedInUserInfo = JSON.parse(localStorage.getItem('user'))
      state.loggedInUserSubscriptionInfo = JSON.parse(localStorage.getItem('subscriptionInfo'))
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        if (parseInt(action.payload.data.user.terms_conditions) === 0) {
          state.loggedInUserInfo = action.payload.data.user
          state.loggedInUserSubscriptionInfo = action.payload.data.subscriptions
          localStorage.setItem('subscriptionInfo', JSON.stringify(action.payload.data.subscriptions));
          state.success = action.payload.success
          state.agree_TaC = false
          const today = new Date()
          const demoDate = new Date(action.payload.data.user.company.demo_untill)
          if (action.payload.data.user.role_id !== 1 && action.payload.data.user.company.demo_untill !== null && demoDate < today) {
            state.demo_expired = true
          }
        } else {
          let user = action.payload.data;
          state.loggedInUserInfo = action.payload.data.user
          state.loggedInUserSubscriptionInfo = action.payload.data.subscriptions
          localStorage.setItem('user', JSON.stringify(user.user));
          localStorage.setItem('subscriptionInfo', JSON.stringify(action.payload.data.subscriptions));
          localStorage.setItem('token', user.token);
          localStorage.setItem('tour_enabled', user.user.tour_enabled);
          localStorage.setItem('userLoginCount', action.payload.data.userLoginCount);
          state.status = action.payload.status;
          state.success = action.payload.success;
          state.token = user.token;
          state.loginSuccess = true
          const today = new Date()
          const demoDate = new Date(action.payload.data.user.company.demo_untill)
          if (action.payload.data.user.role_id !== 1 && action.payload.data.user.company.demo_untill !== null && demoDate < today) {
            state.demo_expired = true
          }
        }
      } else if (action.payload.success === true && parseInt(action.payload.status) === 403) {
        state.alreadyLoggedIn = true
        state.message = action.payload.message
        state.tempToken = action.payload.data.token;
        state.tempUserid = action.payload.data.id;
      } else if (action.payload.success === false && parseInt(action.payload.status) === 404) {
        state.success = false
        state.message = action.payload.message
      } else if (action.payload.success === false && parseInt(action.payload.status) === 422) {
        state.success = false
        state.message = action.payload.message
      } else if (action.payload.success === true && parseInt(action.payload.status) === 402) {
        state.success = false
        state.message = action.payload.message
      } else if (action.payload.success === true && parseInt(action.payload.status) === 310) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.message = action.payload.message;
      }
    });
    builder.addCase(logoutAsync.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        handlelocalStorageClear()
        state.message = null
        state.status = null
        state.success = null
        state.token = null
        state.loginSuccess = false
      } else {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.message = action.payload.message;
      }
    });

    builder.addCase(updateTourStatus.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        if (action.payload.data.action == 4) {
          localStorage.setItem('userLoginCount', 3)
        } else {
          localStorage.setItem('tour_enabled', action.payload.data.action)
        }

      }
    });
  },
})

export const { resetAllState, resetToken, adduserFromLocalStorage } = loginSlice.actions
export const selectStatus = (state) => state.login.status;
export const selectSuccess = (state) => state.login.success;
export const selectMessage = (state) => state.login.message;
export const selectToken = (state) => localStorage.getItem('token');
export default loginSlice.reducer