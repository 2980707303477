import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  errorsFromApiResponse: null,
  users: [],
  addUserCompleted: null,
  lastAddUserId: null,
  updateUserCompleted: null,
  passwordDataUpdateCompleted: null,
  passwordDataUpdateCompletedMessage: null,
  deleteUserCompleted: null,
  userInfo: {},
  companies: [],
  fetchListing: null,
  reverted: null,
  getUserResponse: true,
}

export const fetchCompanyList = createAsyncThunk(
  'user/fetchCompanyList',
  async () => {
    try {
      const response = await API.get(`/admin/organizations-dropdown`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const userList = createAsyncThunk(
  'user/userList',
  async (data) => {
    try {
      const response = await API.post(`/admin/users-list`, data)
      return response
    } catch (err) {
      return err
    }
  }
)
export const userOrganizationList = createAsyncThunk(
  'user/userList',
  async (id) => {
    try {
      const response = await API.post(`/admin/users-list/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)
export const addUser = createAsyncThunk(
  'user/addUser',
  async (data) => {
    try {
      const response = await API.post(`/admin/user`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const editUser = createAsyncThunk(
  'user/editUser',
  async (id) => {
    try {
      const response = await API.get(`/admin/user/${id}/edit`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (data) => {
    try {
      const response = await API.put(`/admin/user/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const passwordDataUpdate = createAsyncThunk(
  'user/passwordDataUpdate',
  async (data) => {
    try {
      const response = await API.post(`/manage-profile/change-password/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const updateUserRoles = createAsyncThunk(
  'user/updateUserRoles',
  async (data) => {
    try {
      const response = await API.post(`/admin/user/roles/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (id) => {
    try {
      const response = await API.delete(`/admin/user/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.addUserCompleted = null
      state.updateUserCompleted = null
      state.deleteUserCompleted = null
      state.passwordDataUpdateCompleted = null
      state.passwordDataUpdateCompletedMessage = null
      state.fetchListing = null
      state.reverted = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.companies = action.payload.data === null ? [] : action.payload.data.companies
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(userList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.users = action.payload.data === null ? [] : action.payload.data.users
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.addUserCompleted = true
        state.lastAddUserId = action.payload.data.employee.id
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.addUserCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.userInfo = action.payload.data.employee
      } else {
        state.userInfo = {};
        state.getUserResponse = false;
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.updateUserCompleted = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.updateUserCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(passwordDataUpdate.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.passwordDataUpdateCompleted = true
        state.passwordDataUpdateCompletedMessage = action.payload.message
      } else if (parseInt(action.payload.status) === 422) {
        state.passwordDataUpdateCompleted = false
        state.passwordDataUpdateCompletedMessage = action.payload.message
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(updateUserRoles.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.updateUserCompleted = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.updateUserCompleted = false
        state.errorsFromApiResponse = action.payload.data === null ? null : action.payload.data.errors
        state.reverted = true
      }
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.deleteUserCompleted = true
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.deleteUserCompleted = false
      }
    });
  },
})

export const { resetAllState } = UserSlice.actions;
export default UserSlice.reducer