import axios from 'axios'
import { handlelocalStorageClear } from '../basic'

// // This is for UAT
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_SITE_API_URL}`
})

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) config.headers['Authorization'] = "Bearer " + token
    return config
})

instance.interceptors.response.use((config) => {
    return config.data
}, (error) => {
    if (error.response.status === 401) {
        // localStorage.clear()
        handlelocalStorageClear()
        return window.location = '/'
    }
    return Promise.reject(error.response.data)
})

export default instance