
import React from 'react';

const HomePage = React.lazy(() => import('../pages/home/Homepage'))
const CustomersList = React.lazy(() => import('../pages/customers/CustomersList'))
const PersonalData = React.lazy(() => import('../pages/administration/PersonalData'))
const ManageSubscription = React.lazy(() => import('../pages/administration/ManageSubscription'))
const CMS = React.lazy(() => import('../pages/admin/cms/CMS'))
const InvoicePurchased = React.lazy(() => import('../pages/administration/InvoicePurchased'))
const Downloads = React.lazy(() => import('../pages/administration/Downloads'))
const Manufacturers = React.lazy(() => import('../pages/manufacturers/Manufacturers'))
const ConsumptionProfiles = React.lazy(() => import('../pages/consumption/ConsumptionProfiles'))
const Contact = React.lazy(() => import('../pages/administration/Contact'))
const Calculation = React.lazy(() => import('../pages/calculations/CalculationsList'))
const Location = React.lazy(() => import('../pages/calculations/Location'))
const ConsumptionData = React.lazy(() => import('../pages/calculations/ConsumptionData'))
const Consumption = React.lazy(() => import('../pages/calculations/Verbrauch'))
const ConsumptionValues = React.lazy(() => import('../pages/calculations/ConsumptionValues'))
const ConsumptionValuesModify = React.lazy(() => import('../pages/calculations/ConsumptionValuesModify'))
const PVSystemTabs = React.lazy(() => import('../pages/calculations/PVSystemTabs'))
const FinancingTabs = React.lazy(() => import('../pages/calculations/FinancingTabs'))
const ReportsTab = React.lazy(() => import('../pages/calculations/ReportsTab'))
const CreateReport = React.lazy(() => import('../pages/calculations/CreateReport'))
const Organisation = React.lazy(() => import('../pages/admin/organisation/OrganisationList'))
const AddOrganisation = React.lazy(() => import('../pages/admin/organisation/AddOrganisation'))
const EditOrganisation = React.lazy(() => import('../pages/admin/organisation/EditOrganisation'))
const AddSubscription = React.lazy(() => import('../pages/admin/subscription/AddSubscription'))
const EditSubscription = React.lazy(() => import('../pages/admin/subscription/EditSubscription'))
const StandardOverviewList = React.lazy(() => import('../pages/admin/defaults/StandardOverviewList'))
const StandardNewEntry = React.lazy(() => import('../pages/admin/defaults/StandardNewEntry'))
const StandardEditEntry = React.lazy(() => import('../pages/admin/defaults/StandardEditEntry'))
const EnergyProduction = React.lazy(() => import('../pages/admin/defaults/EnergyProduction'))
const EmployeeList = React.lazy(() => import('../pages/employees/EmployeeList'))
const AddEmployee = React.lazy(() => import('../pages/employees/AddEmployee'))
const EditEmployee = React.lazy(() => import('../pages/employees/EditEmployee'))
const Invoice = React.lazy(() => import('../pages/administration/Invoice'))
const AddCustomers = React.lazy(() => import('../pages/customers/AddCustomers'))
const EditCustomers = React.lazy(() => import('../pages/customers/EditCustomers'))
const MoveCustomer = React.lazy(() => import('../pages/customers/move-customer/MoveCustomer'))
const LinkExpired = React.lazy(() => import('../components/register-modal/LinkExpired'))
const DemoExpired = React.lazy(() => import('../components/register-modal/DemoExpired'))
const RegisterOffer = React.lazy(() => import('../components/register-modal/RegisterOffer'))
const TermsOfUse = React.lazy(() => import('../components/register-modal/Terms'))
const LegalNotice = React.lazy(() => import('../components/register-modal/LegalNotice'))
const PrivacyPolicy = React.lazy(() => import('../components/register-modal/PrivacyPolicy'))
const RegisterSuccess = React.lazy(() => import('../components/register-modal/RegisterSuccess'))
const ForgotPassword = React.lazy(() => import('../components/forgot-password/ForgotPassword'))
const ResendPasswordMail = React.lazy(() => import('../components/forgot-password/ForgotPasswordResend'))
const ResetPassword = React.lazy(() => import('../components/forgot-password/ResetPassword'))
const VarifyAccount = React.lazy(() => import('../components/register-modal/VerifyAccount'))
const ResetPasswordConfirmation = React.lazy(() => import('../components/forgot-password/ResetPasswordConfirmation'))
const Map = React.lazy(() => import('../components/Map'))
const Chart = React.lazy(() => import('../pages/charts/Chart'))
const ChartDemo = React.lazy(() => import('../pages/charts/ChartDemo'))
const BarChartDemo = React.lazy(() => import('../pages/charts/BarChartDemo'))
const UsersList = React.lazy(() => import('../pages/users/UsersList'))
const AddUser = React.lazy(() => import('../pages/users/AddUser'))
const EditUser = React.lazy(() => import('../pages/users/EditUser'))
const SubscriptionsList = React.lazy(() => import('../pages/admin/subscription/SubscriptionsList'))
const SubscriptionQuit = React.lazy(() => import('../pages/administration/SubscriptionQuit'))

export const CutomerRoutes = [
    {
        path: "/",
        name: HomePage,
        exact: true,
        element: HomePage
    },
    {
        path: "/subscription-quit",
        name: SubscriptionQuit,
        exact: true,
        element: SubscriptionQuit
    },
    {
        path: "/map",
        name: Map,
        exact: true,
        element: Map
    },
    {
        path: "/chart",
        name: Chart,
        exact: true,
        element: Chart
    },
    {
        path: "/chart-demo",
        name: ChartDemo,
        exact: true,
        element: ChartDemo
    },
    {
        path: "/barchart-demo",
        name: BarChartDemo,
        exact: true,
        element: BarChartDemo
    },
    {
        path: "/forgot-password",
        element: ForgotPassword,
        exact: true,
        name: 'ForgotPassword',
    },
    {
        path: "/resend-password",
        element: ResendPasswordMail,
        exact: true,
        name: 'ResendPasswordMail',
    },
    {
        path: "/reset-password/:token?/:password?",
        element: ResetPassword,
        exact: true,
        name: 'ResetPassword',
    },
    {
        path: "/verify-account/:id/:hash",
        element: VarifyAccount,
        exact: true,
        name: VarifyAccount
    },
    {
        path: "/reset-password-confirmation",
        element: ResetPasswordConfirmation,
        exact: true,
        name: 'ResetPasswordConfirmation',
    },
    {
        path: "/registration-success",
        element: RegisterSuccess,
        exact: true,
        name: RegisterSuccess,
    },
    {
        path: "/terms-of-use/:id?",
        element: TermsOfUse,
        exact: false,
        name: TermsOfUse,
    },
    {
        path: "/legal-notice",
        element: LegalNotice,
        exact: true,
        name: LegalNotice,
    },
    {
        path: "/privacy-policy",
        element: PrivacyPolicy,
        exact: true,
        name: PrivacyPolicy,
    },
    {
        path: "/link-expired/:id?",
        element: LinkExpired,
        exact: true,
        name: LinkExpired,
    },
    {
        path: "/demo-expired/:id?",
        element: DemoExpired,
        exact: true,
        name: DemoExpired,
    },
    {
        path: "/offer",
        element: RegisterOffer,
        exact: true,
        name: RegisterOffer,
    },
    {
        path: "/customers",
        element: CustomersList,
        exact: true,
        name: 'CustomersList',
    },
    {
        path: "/add-customer",
        name: AddCustomers,
        exact: true,
        element: AddCustomers
    },
    {
        path: "/edit-customer/:id",
        name: EditCustomers,
        exact: true,
        element: EditCustomers
    },
    {
        path: "/move-customer/:id/:firstname/:lastname",
        name: MoveCustomer,
        exact: true,
        element: MoveCustomer
    },
    {
        path: "/personal-data",
        name: PersonalData,
        exact: true,
        element: PersonalData
    },
    {
        path: "/manage-subscription",
        name: ManageSubscription,
        exact: true,
        element: ManageSubscription
    },
    {
        path: "/manage-subscription-invoice",
        name: Invoice,
        exact: true,
        element: Invoice
    },
    {
        path: "/invoice-purchased",
        name: InvoicePurchased,
        exact: true,
        element: InvoicePurchased
    },
    {
        path: "/cms",
        name: CMS,
        exact: true,
        element: CMS
    },
    {
        path: "/employees",
        name: EmployeeList,
        exact: true,
        element: EmployeeList
    },
    {
        path: "/add-employee",
        name: AddEmployee,
        exact: true,
        element: AddEmployee
    },
    {
        path: "/edit-employee/:id",
        name: EditEmployee,
        exact: true,
        element: EditEmployee
    },
    {
        path: "/downloads",
        name: Downloads,
        exact: true,
        element: Downloads
    },
    {
        path: "/manufacturers",
        name: Manufacturers,
        exact: true,
        element: Manufacturers
    },
    {
        path: "/consumption-profiles",
        name: ConsumptionProfiles,
        exact: true,
        element: ConsumptionProfiles
    },
    {
        path: "/contact",
        name: Contact,
        exact: true,
        element: Contact
    },
    {
        path: "/calculation",
        name: Calculation,
        exact: true,
        element: Calculation
    },
    {
        path: "/location/:id",
        name: Location,
        exact: true,
        element: Location
    },
    {
        path: "/consumption-upload-data/:id",
        name: ConsumptionData,
        exact: true,
        element: ConsumptionData
    },
    {
        path: "/consumption/:id",
        name: Consumption,
        exact: true,
        element: Consumption
    },
    {
        path: "/consumption-values/:id",
        name: ConsumptionValues,
        exact: true,
        element: ConsumptionValues
    },
    {
        path: "/consumption-values-modify/:id",
        name: ConsumptionValuesModify,
        exact: true,
        element: ConsumptionValuesModify
    },
    {
        path: "/pv-system/:id",
        name: PVSystemTabs,
        exact: true,
        element: PVSystemTabs
    },
    {
        path: "/financing/:id",
        name: FinancingTabs,
        exact: true,
        element: FinancingTabs
    },
    {
        path: "/users",
        name: UsersList,
        exact: true,
        element: UsersList
    },
    {
        path: "/reports/:id",
        name: ReportsTab,
        exact: true,
        element: ReportsTab
    },
    {
        path: "/reports/createreport/:id/:apiName",
        name: CreateReport,
        exact: true,
        element: CreateReport
    },
    {
        path: "/add-user",
        name: AddUser,
        exact: true,
        element: AddUser
    },
    {
        path: "/edit-user/:id",
        name: EditUser,
        exact: true,
        element: EditUser
    },
    {
        path: "/organisation",
        name: Organisation,
        exact: true,
        element: Organisation
    },
    {
        path: "/add-organisation",
        name: AddOrganisation,
        exact: true,
        element: AddOrganisation
    },
    {
        path: "/edit-organisation/:id",
        name: EditOrganisation,
        exact: true,
        element: EditOrganisation
    },
    {
        path: "/add-subscription/:id/:company",
        name: AddSubscription,
        exact: true,
        element: AddSubscription
    },
    {
        path: "/edit-subscription/:subscription/:id/:company",
        name: EditSubscription,
        exact: true,
        element: EditSubscription
    },
    {
        path: "/standard-overview-list",
        name: StandardOverviewList,
        exact: true,
        element: StandardOverviewList
    },
    {
        path: "/standard-new-entry",
        name: StandardNewEntry,
        exact: true,
        element: StandardNewEntry
    },
    {
        path: "/standard-edit-entry/:property_name",
        name: StandardEditEntry,
        exact: true,
        element: StandardEditEntry
    },
    {
        path: "/energy-production",
        name: EnergyProduction,
        exact: true,
        element: EnergyProduction
    },
    {
        path: "/subscriptions",
        name: SubscriptionsList,
        exact: true,
        element: SubscriptionsList
    },
];

