import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../banner/banner";
import { handlelocalStorageClear } from '../../basic';

const initialState = {
  status: null,
  message: null,
  errorsFromApiResponse: null,
  registrationComplete: null,
  agreeTermsComplete: null,
  resendEmailLoader: false,
  userToken: null,
  fetchUserInfoComplete: null,
  nextUrl: null,
  verifyAccountComplete: null,
  emailUniqueError: null,
  companyUniqueError: null,
  clearDataAndLogoutProcessCompleted: null,
  offerOrderNowComplete: null,
  mailURLFromResponse: null
}

export const registerAsync = createAsyncThunk(
  'register/postRegister',
  async (data) => {
    try {
      const response = await API.post('/register', data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const mailResend = createAsyncThunk(
  'register/mailResend',
  async (token) => {
    try {
      const response = await API.post(
        '/email/verification-notification',
        {},
        {
          headers: {
            "Authorization": "Bearer " + token
          }
        }
      )
      return response
    } catch (err) {
      return err
    }
  }
)

export const checkUniqueEmail = createAsyncThunk(
  'register/checkUniqueEmail',
  async (data) => {
    try {
      const response = await API.post(`/check-email`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const checkUniqueCompany = createAsyncThunk(
  'register/checkUniqueCompany',
  async (data) => {
    try {
      const response = await API.post(`/check-organization`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const fetchUserInfo = createAsyncThunk(
  'register/fetchUserInfo',
  async (data) => {
    try {
      const response = await API.post(`/get-user/${data}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const verifyAccount = createAsyncThunk(
  'register/verifyAccount',
  async (data) => {
    try {
      const response = await API.post(`/email/verify/${data.id}/${data.hash}`, {}, {
        headers: {
          "Authorization": "Bearer " + data.token
        }
      })
      return response
    } catch (err) {
      return err
    }
  }
)

export const agreeTerms = createAsyncThunk(
  'register/agreeTerms',
  async (data) => {
    try {
      const response = await API.post(`/update-terms/${data}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const clearDataAndLogout = createAsyncThunk(
  'register/clearDataAndLogout',
  async (data) => {
    try {
      const response = await API.post(`/clear-data-and-logout`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const offerOrderNow = createAsyncThunk(
  'register/offerOrderNow',
  async (data) => {
    try {
      const response = await API.get(`/offer`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null
      state.status = null
      state.registrationComplete = null
      state.companyUniqueError = null
      state.emailUniqueError = null
      state.agreeTermsComplete = null
      state.clearDataAndLogoutProcessCompleted = null
      state.offerOrderNowComplete = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(offerOrderNow.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.offerOrderNowComplete = true
        state.mailURLFromResponse = action.payload.data.email_url
      } else {
        state.offerOrderNowComplete = true
        ErrorBanner(action.payload.message)
      }
    });

    builder.addCase(clearDataAndLogout.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.clearDataAndLogoutProcessCompleted = true
        // localStorage.clear()
        handlelocalStorageClear()
      } else {
        state.status = action.payload.status
        ErrorBanner(action.payload.message)
      }
    });

    builder
      .addCase(registerAsync.pending, (state) => { })
      .addCase(registerAsync.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.registrationComplete = true
          localStorage.setItem('tempTokenOnRegister', JSON.stringify(action.payload.data.token))
          localStorage.setItem('tempUserdataOnRegister', JSON.stringify(action.payload.data.user))
        } else if (parseInt(action.payload.status) === 422) {
          state.message = action.payload.message
          state.errorsFromApiResponse = action.payload.data.errors
        }
      });

    builder
      .addCase(mailResend.pending, (state) => {
        state.resendEmailLoader = true
      })
      .addCase(mailResend.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.resendEmailLoader = false
          SuccessBanner(action.payload.message)
        } else {
          state.resendEmailLoader = false
          ErrorBanner(action.payload.message)
        }
      });

    builder
      .addCase(verifyAccount.pending, (state) => { })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.verifyAccountComplete = true
          state.nextUrl = 'terms-of-use'
        } else if (parseInt(action.payload.status) === 403) {
          state.verifyAccountComplete = true
          state.nextUrl = 'link-expired'
          ErrorBanner(action.payload.message)
        } else if (parseInt(action.payload.status) === 422) {
          ErrorBanner(action.payload.message)
          state.verifyAccountComplete = true
          state.nextUrl = 'homepage'
        }
      });

    builder
      .addCase(fetchUserInfo.pending, (state) => { })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          localStorage.setItem('UserForVerification', JSON.stringify(action.payload.data.user))
          state.userToken = action.payload.data.user.access_token
          state.fetchUserInfoComplete = true
        } else if (parseInt(action.payload.status) === 404) {
          ErrorBanner(action.payload.message)
        }
      });

    builder.addCase(agreeTerms.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        localStorage.removeItem('UserForVerification')
        localStorage.setItem('user', JSON.stringify(action.payload.data.user))
        localStorage.setItem('token', action.payload.data.user.access_token)
        localStorage.setItem('tour_enabled', action.payload.data.user.tour_enabled ?? 0);
        state.agreeTermsComplete = true
      } else {
        ErrorBanner(action.payload.message)
      }
    });

    builder
      .addCase(checkUniqueEmail.pending, (state, action) => {
        state.emailUniqueError = ''
      })
      .addCase(checkUniqueEmail.fulfilled, (state, action) => {
        if (action.payload.success === false && parseInt(action.payload.status) === 422) {
          state.emailUniqueError = action.payload.data.errors.email
        } else {
          state.emailUniqueError = null
        }
      });
    builder
      .addCase(checkUniqueCompany.pending, (state) => {
        state.companyUniqueError = ''
      })
      .addCase(checkUniqueCompany.fulfilled, (state, action) => {
        if (action.payload.success === false && parseInt(action.payload.status) === 422) {
          state.companyUniqueError = action.payload.data.errors.company
        } else {
          state.companyUniqueError = null
        }
      });
  },
})
export const { resetAllState } = registerSlice.actions;
export const selectStatus = (state) => state.register.status;
export const selectMessage = (state) => state.register.message;
export default registerSlice.reducer