import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../../services/api';
import { toast } from 'react-toastify';

const initialState = {
  status: null,
  success: null,
  message: null,
  cmsData: [],
  rolesData: {},
  errorsFromApiResponse: null
}

export const fetchCMSData = createAsyncThunk(
  'cms/fetchCMSData',
  async () => {
    try {
      const response = await API.get(`/admin/cms`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const getUserRole = createAsyncThunk(
  'cms/getUserRole',
  async (id) => {
    try {
      const response = await API.get(`/get-user-roles/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const createupdateCMS = createAsyncThunk(
  'cms/createCMS',
  async ({data}) => {
    try {
      const response = await API.post(`/admin/cms`, data, {
        headers: {
          "Content-type": "multipart/form-data"
        }
      })
      return response
    } catch (err) {
      return err
    }
  }
)

export const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null
      state.status = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCMSData.pending, (state) => { })
      .addCase(fetchCMSData.fulfilled, (state, action) => {
        if (action.payload.success === true && parseInt(action.payload.status) === 200) {
          state.status = action.payload.status;
          state.success = action.payload.success;
          state.cmsData = action.payload.data.cmsData
        } else {
          // toast.error(action.payload.message)
          state.message = action.payload.message
        }
      });
    builder
      .addCase(getUserRole.pending, (state) => { })
      .addCase(getUserRole.fulfilled, (state, action) => {
        if (action.payload.success === true && parseInt(action.payload.status) === 200) {
          state.status = action.payload.status;
          state.success = action.payload.success;
          state.rolesData = action.payload.data.user_roles
        } else {
          // toast.error(action.payload.message)
          state.message = action.payload.message
        }
      });
    builder
      .addCase(createupdateCMS.pending, (state) => { })
      .addCase(createupdateCMS.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.status = action.payload.status;
          state.success = action.payload.success;
          toast.success(action.payload.message)
        } else if (parseInt(action.payload.status) === 422) {
          state.message = action.payload.message
          state.errorsFromApiResponse = action.payload.data.errors
        }
      });
  },
})
export const { resetAllState } = cmsSlice.actions;
export default cmsSlice.reducer