import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { toast } from 'react-toastify';

const initialState = {
  message: null, submitProcessCompleted: null, errorsFromApiResponse: null
}

export const SubmitContactForm = createAsyncThunk(
  'header/SubmitContactForm',
  async (data) => {
    try {
      const response = await API.post(`/talk-to-expert`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const HeaderSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null
      state.submitProcessCompleted = null
      state.errorsFromApiResponse = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(SubmitContactForm.fulfilled, (state, action) => {
        console.log('Contact from submit response :- ', action.payload)
        if (action.payload.success === true && parseInt(action.payload.status) === 200) {
          state.submitProcessCompleted = true
          toast.success(action.payload.message)
        } else if (parseInt(action.payload.status) === 422) {
          state.submitProcessCompleted = false
          state.message = action.payload.message
          state.errorsFromApiResponse = action.payload.data.errors
        }
      });
  },
})
export const { resetAllState } = HeaderSlice.actions;
export default HeaderSlice.reducer