import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";
// eslint-disable-next-line
// import { id } from 'chartjs-plugin-dragdata';

const initialState = {
  responseMessage: null,
  numberOfPerson: null,
  monthsSelected: null,
  orderNowCompleted: null,
  personalDataUpdateCompleted: null,
  organizationDataUpdateCompleted: null,
  passwordDataUpdateCompleted: null,
  errorsFromApiResponse: null,
  organizationData: {},
  subscriptions: [],
  UserInfoFromAPI: {},
  passwordDataUpdateCompletedMessage: null,
  contactFormSubmitted: null,
  unsavedChanges: false,
  fetchListing: null,
  subscriptionQuitComplete: null
}

export const continueToOrder = createAsyncThunk(
  'administration/continueToOrder',
  async (data) => { return data }
)

export const unsavedChangesFunc = createAsyncThunk(
  'administration/unsavedChangesFunc',
  async (data) => {
    return data
  }
)

export const orderNow = createAsyncThunk(
  'administration/orderNow',
  async (data) => {
    try {
      return await API.post(`/add-subscription`, data)
    } catch (err) {
      return err
    }
  }
)

export const getUserInfo = createAsyncThunk(
  'administration/getUserInfo',
  async (id) => {
    try {
      return await API.get(`/get-user/${id}`)
    } catch (err) {
      return err
    }
  }
)

export const personalDataUpdate = createAsyncThunk(
  'administration/personalDataUpdate',
  async (data) => {
    try {
      const response = await API.post(`/manage-profile/personal/${data.id}`, data, {
        headers: {
          "Content-type": "multipart/form-data"
        }
      })
      return response
    } catch (err) {
      return err
    }
  }
)

export const organizationDataFetch = createAsyncThunk(
  'administration/organizationDataFetch',
  async (data) => {
    try {
      const response = await API.get(`/manage-profile/getorganization`, {})
      return response
    } catch (err) {
      return err
    }
  }
)

export const organizationDataUpdate = createAsyncThunk(
  'administration/organizationDataUpdate',
  async (data) => {
    try {
      const response = await API.post(`/manage-profile/organization/${data.id}`, data, {
        headers: {
          "Content-type": "multipart/form-data"
        }
      })
      return response
    } catch (err) {
      return err
    }
  }
)

export const passwordDataUpdate = createAsyncThunk(
  'administration/passwordDataUpdate',
  async (data) => {
    try {
      const response = await API.post(`/manage-profile/change-password/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionList = createAsyncThunk(
  'administration/subscriptionList',
  async (data) => {
    try {
      if (data.sortOrder === null || data.sortOrder === undefined) {
        const response = await API.get(`/subscriptions?page=${data.page}`)
        return response
      } else {
        const response = await API.get(`/subscriptions?page=${data.page}&sortBy=${data.sortBy}&sortOrder=${data.sortOrder}`)
        return response
      }
    } catch (err) {
      return err
    }
  }
)

export const contact = createAsyncThunk(
  'administration/contact',
  async (data) => {
    try {
      const response = await API.post(`/contact`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const subscriptionQuit = createAsyncThunk(
  'administration/subscriptionQuit',
  async (data) => {
    try {
      const response = await API.post(`/quit-subscription/${data.id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const AdministrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.numberOfPerson = null
      state.monthsSelected = null
      state.orderNowCompleted = null
      state.personalDataUpdateCompleted = null
      state.organizationDataUpdateCompleted = null
      state.responseMessage = null
      state.passwordDataUpdateCompleted = null
      state.AddOrUpdateManufacturers = null
      state.fetchListing = null
      state.subscriptionQuitComplete = null
    },
    resetCustomStateOne: (state) => {
      state.subscriptionQuitComplete = null
    },
    resetStateByName: (state, action) => {
      state[action.payload] = null
    },
    resetOrderComplete: (state) => {
      state.orderNowCompleted = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(unsavedChangesFunc.fulfilled, (state, action) => {
      state.unsavedChanges = action.payload.value
    });
    builder.addCase(subscriptionQuit.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.subscriptionQuitComplete = true
        SuccessBanner(action.payload.message)
      } else {
        state.subscriptionQuitComplete = true
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(contact.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.contactFormSubmitted = true
        SuccessBanner(action.payload.message)
      } else {
        state.contactFormSubmitted = false
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.UserInfoFromAPI = action.payload.data.user
      } else {
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(subscriptionList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.subscriptions = action.payload.data === null ? [] : action.payload.data.subscriptions
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(continueToOrder.fulfilled, (state, action) => {
      state.numberOfPerson = action.payload.number_of_person
      state.monthsSelected = action.payload.months
    });
    builder
      .addCase(orderNow.pending, (state) => { })
      .addCase(orderNow.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.orderNowCompleted = true
        } else if (parseInt(action.payload.status) === 422) {
          ErrorBanner(action.payload.message)
          state.orderNowCompleted = false
        }
      });
    builder.addCase(organizationDataFetch.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.organizationData = action.payload.data.company
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(personalDataUpdate.fulfilled, (state, action) => {
      // state.responseMessage = action.payload.message
      if (parseInt(action.payload.status) === 200) {
        localStorage.setItem('user', JSON.stringify(action.payload.data.user))
        state.personalDataUpdateCompleted = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.personalDataUpdateCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(organizationDataUpdate.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.organizationDataUpdateCompleted = true
        state.errorsFromApiResponse = null
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.organizationDataUpdateCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(passwordDataUpdate.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.passwordDataUpdateCompleted = true
        state.passwordDataUpdateCompletedMessage = action.payload.message
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.passwordDataUpdateCompleted = false
        state.passwordDataUpdateCompletedMessage = action.payload.message
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
  },
})

export const { resetAllState, resetStateByName, resetOrderComplete, resetCustomStateOne } = AdministrationSlice.actions;
export default AdministrationSlice.reducer