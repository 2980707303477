import { toast } from 'react-toastify';

export const ErrorBanner = (message) => {
    toast.error(message)
};
export const SuccessBanner = (message) => {
    toast.success(message)
};
export const WarningBanner = (message) => {
    toast.warning(message)
};
export const CustomWaringBanner = (message) => {
    toast.error("Hier ist leider etwas schiefgelaufen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns und geben Sie die Seite oder den Abschnitt an, auf der/dem der Fehler aufgetreten ist, sowie die Uhrzeit, damit wir den Fehler schnell beheben können.")
};
