import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  passwordDataUpdateCompleted: null,
  errorsFromApiResponse: null,
  employees: [],
  addEmployeeCompleted: null,
  updateEmployeeCompleted: null,
  deleteEmployeeCompleted: null,
  lastAddEmployeeId: null,
  employeeInfo: {},
  passwordDataUpdateCompletedMessage: null,
  fetchListing: null,
  employeeRolesData: {},
  reverted: null,
  getEmployeeResponse: true,
}

export const passwordDataUpdate = createAsyncThunk(
  'employee/passwordDataUpdate',
  async (data) => {
    try {
      const response = await API.post(`/manage-profile/change-password/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const employeeList = createAsyncThunk(
  'employee/employeeList',
  async (data) => {
    try {
      const response = await API.post(`/administration/employee-list`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const employeeRoles = createAsyncThunk(
  'employee/employeeRoles',
  async (id) => {
    try {
      const response = await API.get(`/administration/employee/roles/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const addEmployee = createAsyncThunk(
  'employee/addEmployee',
  async (data) => {
    try {
      const response = await API.post(`/administration/employee`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const editEmployee = createAsyncThunk(
  'employee/editEmployee',
  async (id) => {
    try {
      const response = await API.get(`/administration/employee/${id}/edit`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (data) => {
    try {
      const response = await API.put(`/administration/employee/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const updateEmployeeRoles = createAsyncThunk(
  'employee/updateEmployeeRoles',
  async (data) => {
    try {
      const response = await API.post(`/administration/employee/roles/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const deleteEmployee = createAsyncThunk(
  'employee/deleteEmployee',
  async (id) => {
    try {
      const response = await API.delete(`/administration/employee/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const EmployeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.addEmployeeCompleted = null
      state.updateEmployeeCompleted = null
      state.deleteEmployeeCompleted = null
      state.passwordDataUpdateCompleted = null
      state.passwordDataUpdateCompletedMessage = null
      state.fetchListing = null
      state.reverted = null
    },
    resetCustomState: (state) => {
      state.employeeInfo = {}
      state.employeeRolesData = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(passwordDataUpdate.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.passwordDataUpdateCompleted = true
        state.passwordDataUpdateCompletedMessage = action.payload.message
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        state.passwordDataUpdateCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(employeeList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.employees = action.payload.data === null ? [] : action.payload.data.employees
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(addEmployee.fulfilled, (state, action) => {
      console.log(action.payload)
      if (parseInt(action.payload.status) === 200) {
        state.addEmployeeCompleted = true
        state.lastAddEmployeeId = action.payload.data.employee.id
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.addEmployeeCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(editEmployee.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.getEmployeeResponse = true;
        state.employeeInfo = action.payload.data.employee
      } else {
        state.getEmployeeResponse = false;
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(employeeRoles.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.employeeRolesData = action.payload.data.company_with_roles
      } else {
        ErrorBanner(action.payload.message)
      }
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.updateEmployeeCompleted = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.updateEmployeeCompleted = false
        state.errorsFromApiResponse = action.payload.data.errors
      }
    });
    builder.addCase(updateEmployeeRoles.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.updateEmployeeCompleted = true
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.updateEmployeeCompleted = false
        state.reverted = true
      }
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.deleteEmployeeCompleted = true
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.deleteEmployeeCompleted = false
      }
    });
  },
})

export const { resetAllState, resetCustomState } = EmployeeSlice.actions;
export default EmployeeSlice.reducer