import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import HideP from "../../assets/images/PasswordHide.svg";
import ShowP from "../../assets/images/PasswordVis.svg";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { Col, FormGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { checkUniqueEmail, checkUniqueCompany, registerAsync, resetAllState } from "./RegisterModalSlice";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha"

export default function RegisterModal(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [Loader, setLoader] = useState(false)
  const [isUniqueEmail, setIsUniqueEmail] = useState(true)
  const [isUniqueCompany, setIsUniqueCompany] = useState(true)
  function isValidEmail(email) { return /\S+@\S+\.\S+/.test(email); }
  const { emailUniqueError, companyUniqueError } = useSelector((state) => state.register)
  const errorsFromApiResponse = useSelector((state) => state.register.errorsFromApiResponse)
  const registrationComplete = useSelector((state) => state.register.registrationComplete)
  const [passwordShowHide, setPasswordShowHide] = useState('password')
  const [confirmPasswordShowHide, setConfirmPasswordShowHide] = useState('password')
  const [atLeastOneCapitalLetter, setAtLeastOneCapitalLetter] = useState('#D9D9D9')
  const [atLeastOneSpecialCharactor, setAtLeastOneSpecialCharactor] = useState('#D9D9D9')
  const [atLeastOneNumber, setAtLeastOneNumber] = useState('#D9D9D9')
  const [atLeastMinimumEightCharactor, setAtLeastMinimumEightCharactor] = useState('#D9D9D9')
  const [googleCaptchaVerified, setGoogleCaptchaVerified] = useState(false)
  const uppercaseRegExp = /(?=.*?[A-Z])/
  const digitsRegExp = /(?=.*?[0-9])/
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/
  const minLengthRegExp = /.{8,}/

  const googleReCaptchaOnChange = (value) => { setGoogleCaptchaVerified(value !== null ? true : false) }

  const { values, errors, touched, dirty, isValid, setErrors, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: "", salutation: "", company: "", first_name: "", last_name: "", password: "", password_confirmation: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein.').required('Das Feld E-Mail ist erforderlich.').test(
        'oneOfRequired',
        'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        (value) => { return isValidEmail(value) }
      ),
      // email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein.').matches(/^[aA-zZ0-9@.\s]+$/, "Bitte geben Sie eine gültige E-Mail-Adresse ein."),
      salutation: Yup.string().required('Das Feld Anrede ist erforderlich.').matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      company: Yup.string().required('Das Feld Firma ist erforderlich.').min(3, 'Der Firmenname muss mindestens 3 Zeichen lang sein'),
      first_name: Yup.string().required('Das Feld Vorname ist erforderlich.').matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      last_name: Yup.string().required('Das Feld Nachname ist erforderlich.').matches(/^([^0-9]*)$/, "In diesem Feld sind keine Zahlen zulässig."),
      password: Yup.string().min(8, 'Das neue Passwort braucht eine Mindeslänge von 8 Zeichen.').required('Das Feld Passwort ist erforderlich.'),
      password_confirmation: Yup.string().required('Das Feld Passwort wiederholen ist erforderlich.').oneOf([Yup.ref("password"), null], "Das neue Passwort und die Bestätigung stimmt nicht überein.")
    }),
    onSubmit: async (values, action) => {
      setLoader(true)
      dispatch(registerAsync(values))
    }
  })

  useEffect(() => {
    if (isValidEmail(values.email) === true) { dispatch(checkUniqueEmail({ email: values.email })) }
    if (values.company.length > 2) { dispatch(checkUniqueCompany({ company: values.company })) }
    // eslint-disable-next-line
  }, [values.email, values.company])

  useEffect(() => {
    setIsUniqueEmail(emailUniqueError !== null ? false : true)
    setIsUniqueCompany(companyUniqueError !== null ? false : true)
  }, [emailUniqueError, companyUniqueError])

  useEffect(() => {
    if (errorsFromApiResponse) {
      setLoader(false)
      setErrors({
        'email': errorsFromApiResponse.email,
        'company': errorsFromApiResponse.company,
        'salutation': errorsFromApiResponse.salutation,
        'first_name': errorsFromApiResponse.first_name,
        'last_name': errorsFromApiResponse.last_name,
        'password': errorsFromApiResponse.password
      })
    }
    if (registrationComplete) {
      setLoader(false)
      setAtLeastOneCapitalLetter('#D9D9D9')
      setAtLeastOneSpecialCharactor('#D9D9D9')
      setAtLeastOneNumber('#D9D9D9')
      setAtLeastMinimumEightCharactor('#D9D9D9')
      props.RegisterhandleClose(true)
      resetForm()
      navigate('/registration-success')
      dispatch(resetAllState())
    }
    // eslint-disable-next-line
  }, [errorsFromApiResponse, registrationComplete])

  return (
    <div className="">
      <Modal
        centered
        show={props.registerShow}
        onHide={props.RegisterhandleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="registration-box-custom-width scroll-active"
      >
        <Modal.Header onClick={() => {
          resetForm()
          setPasswordShowHide('password')
          setConfirmPasswordShowHide('password')
          setAtLeastOneCapitalLetter('#D9D9D9')
          setAtLeastOneSpecialCharactor('#D9D9D9')
          setAtLeastOneNumber('#D9D9D9')
          setAtLeastMinimumEightCharactor('#D9D9D9')
          dispatch(resetAllState())
        }} closeButton></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">E-Mail</label>
                  <input
                    type="email"
                    name="email"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ?
                    <span className="error-massage">{errors.email}</span> : <span className="error-massage">{emailUniqueError !== null ? emailUniqueError : " "}&nbsp;</span>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">Anrede</label>
                  <input
                    type="text"
                    name="salutation"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.salutation}
                  />
                  {errors.salutation && touched.salutation ?
                    <span className="error-massage">{errors.salutation}</span> : <span className="error-massage">&nbsp;</span>}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="Firma">Firma</label>
                  <input
                    type="text"
                    name="company"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                  {errors.company && touched.company ? <span className="error-massage">{errors.company}</span> : <span className="error-massage">{companyUniqueError !== null ? companyUniqueError : " "}&nbsp;</span>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">Vorname</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  {errors.first_name && touched.first_name ?
                    <span className="error-massage">{errors.first_name}</span> : <span className="error-massage">&nbsp;</span>}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">Nachname</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  {errors.last_name && touched.last_name ?
                    <span className="error-massage">{errors.last_name}</span> : <span className="error-massage">&nbsp;</span>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">Passwort</label>
                  <div className="password-feild">
                    <input
                      type={passwordShowHide}
                      name="password"
                      placeholder=" "
                      onChange={(e) => {
                        handleChange(e)
                        if (uppercaseRegExp.test(e.currentTarget.value)) { setAtLeastOneCapitalLetter('#9AC56A') } else { setAtLeastOneCapitalLetter('#D9D9D9') }
                        if (specialCharRegExp.test(e.currentTarget.value)) { setAtLeastOneSpecialCharactor('#9AC56A') } else { setAtLeastOneSpecialCharactor('#D9D9D9') }
                        if (digitsRegExp.test(e.currentTarget.value)) { setAtLeastOneNumber('#9AC56A') } else { setAtLeastOneNumber('#D9D9D9') }
                        if (minLengthRegExp.test(e.currentTarget.value)) { setAtLeastMinimumEightCharactor('#9AC56A') } else { setAtLeastMinimumEightCharactor('#D9D9D9') }
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <Link
                      className="custom-link"
                      onClick={() => {
                        if (passwordShowHide === 'text') {
                          setPasswordShowHide('password')
                        } else if (passwordShowHide === 'password') {
                          setPasswordShowHide('text')
                          setConfirmPasswordShowHide('password')
                        }
                      }}
                    >
                      {passwordShowHide === 'text' ? (
                        <img src={HideP} className="hide-password" alt="" />
                      ) : (
                        <img src={ShowP} className="show-password" alt="" />
                      )}
                    </Link>
                  </div>
                  {errors.password && touched.password ?
                    <span className="error-massage">{errors.password}</span> : <span className="error-massage">&nbsp;</span>}
                </FormGroup>
                <FormGroup className="custom-form-control" controlId="formBasicEmail">
                  <label htmlFor="email">Passwort wiederholen</label>
                  <div className="password-feild">
                    <input
                      type={confirmPasswordShowHide}
                      name="password_confirmation"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password_confirmation}
                    />
                    <Link
                      className="custom-link"
                      onClick={() => {
                        if (confirmPasswordShowHide === 'text') {
                          setConfirmPasswordShowHide('password')
                        } else if (confirmPasswordShowHide === 'password') {
                          setConfirmPasswordShowHide('text')
                          setPasswordShowHide('password')
                        }
                      }}
                    >
                      {confirmPasswordShowHide === 'text' ? (
                        <img src={HideP} className="hide-password" alt="" />
                      ) : (
                        <img src={ShowP} className="show-password" alt="" />
                      )}
                    </Link>
                  </div>
                  {errors.password_confirmation && touched.password_confirmation ?
                    <span className="error-massage">{errors.password_confirmation}</span> : <span className="error-massage">&nbsp;</span>}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup className="custom-form-control m-0" controlId="formBasicEmail">
                  <ul className="register-pass-right-list">
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="16"
                          height="16"
                          rx="8"
                          fill={atLeastOneCapitalLetter}
                        />
                        <path
                          d="M3.7002 8.5L6.52862 11.3284L12.1855 5.67157"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Ein Großbuchstabe</span>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="16"
                          height="16"
                          rx="8"
                          fill={atLeastOneSpecialCharactor}
                        />
                        <path
                          d="M3.7002 8.5L6.52862 11.3284L12.1855 5.67157"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Ein Sonderzeichen</span>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="16"
                          height="16"
                          rx="8"
                          fill={atLeastOneNumber}
                        />
                        <path
                          d="M3.7002 8.5L6.52862 11.3284L12.1855 5.67157"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Eine Zahl</span>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="16"
                          height="16"
                          rx="8"
                          fill={atLeastMinimumEightCharactor}
                        />
                        <path
                          d="M3.7002 8.5L6.52862 11.3284L12.1855 5.67157"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Mindestens 8 Zeichen lang</span>
                    </li>
                  </ul>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="custom-form-control">
                <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onChange={googleReCaptchaOnChange} />
                {/* { message ? <span className="error-massage">{message}</span> : <span className="error-massage">&nbsp;</span> } */}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={2}>
                <Button
                  type="submit"
                  disabled={!(isValid && dirty && isUniqueEmail && isUniqueCompany && googleCaptchaVerified)}
                  className="btn btn-primary btn btn-primary d-inline-flex align-items-center"
                >
                  Registrieren&nbsp;{Loader ? <Spinner animation="border" size="sm" role="status"><span className="visually-hidden">Loading...</span></Spinner> : null}
                </Button>
              </Col>
              <Col md={10}>
                <label className="custom-checkbox mb-0">
                  <p>
                    Wenn Sie einen Account erstellen, erklären Sie sich mit
                    den
                    <Link
                      // onClick={() => {props.RegisterhandleClose(true)}}
                      target="_blank"
                      to={'/terms-of-use'} className="custom-link-info ms-1 me-1">
                      PVfin Nutzungsbedingungen
                    </Link>{" "}
                    einverstanden.
                  </p>
                </label>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
