import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../services/api";
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  status: null,
  success: null,
  message: null,
  customer: null,
  lat: null,
  lng: null,
  irridation_value: null,
  calculation: null,
  calculations: [],
  showEmail: true,
  createCalculationCompleted: null,
  lastInsertedID: null,
  errorsFromApiResponse: null,
  consumptionDropdowns: [],
  manufacturerDropdowns: [],
  modelDropdowns: [],
  calculationValues: [],
  storageFields: {},
  updateLocationCompleted: null,
  updateProfileCompleted: null,
  updateConsumptionCompleted: null,
  updatePVSystemFirstTabCompleted: null,
  updatePVSystemSecondTabCompleted: null,
  updatePVSystemThirdTabCompleted: null,
  updatePVSystemFourthTabCompleted: null,
  updateFinancingFirstTabCompleted: null,
  updateFinancingSecondTabCompleted: null,
  copyCalculationCompleted: null,
  makeCalculationDefaultCompleted: null,
  verburchChartData: [],
  AkkufüllstandChartData: [],
  ErzeugungChartData: [],
  consumptionProfilesDropdown: [],
  verbrauchReportProcessCompleted: null,
  verbrauchReportURL: null,
  pvangaleReportProcessCompleted: null,
  pvangaleReportURL: null,
  consumption_data: null,
  updateConsumptionProfileProcessComplete: null,
  getChart1data: [],
  getChart2data: [],
  getChart3data: [],
  getCustomerProcessComplete: null,
  werktags: null,
  wochenede: null,
  pvAuswertungReportProcessCompleted: null,
  pvAuswertungReportURL: null,
  updateCalculationProcess: null,
  defualtProfileId: null,
  uploadCSVProcessComplete: null,
  fetchListing: null,
  proposalValue: null,
  defualtProfileIdFirstTab: null,
  defaultConsumptionData: null,
  defaultConsumptionOtherData: null,
  profileDropdownUserStatus: null,
  getIrridationProcessCompleted: null,
  fetchCalculationCompleted: null,
  consumptionProfileStatus: false,
  calculationStatus: true,
  herstellerExchangeCost: "0,00",
  tax_type: null,
  mounting_position: null,
  verbrauchJsonCount: 0,
  custom_feed_in_tariff: null,
  showPvSystemTour: false,
};

export const getAllCalculation = createAsyncThunk(
  "calculation/getAllCalculation",
  async (data) => {
    try {
      const response = await API.post(`/calculations`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCustomer = createAsyncThunk(
  "calculation/getCustomer",
  async (id) => {
    try {
      const response = await API.get(`/get-customer/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const createCalculation = createAsyncThunk(
  "calculation/createCalculation",
  async (data) => {
    try {
      const response = await API.post(`/calculation/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const copyCalculation = createAsyncThunk(
  "calculation/copyCalculation",
  async (id) => {
    try {
      const response = await API.post(`/copy-calculation/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const makeCalculationDefault = createAsyncThunk(
  "calculation/makeCalculationDefault",
  async (id) => {
    try {
      const response = await API.put(`/make-calculation-default/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCalculation = createAsyncThunk(
  "calculation/getCalculation",
  async (id) => {
    try {
      const response = await API.get(`/calculation/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateCalculation = createAsyncThunk(
  "calculation/updateCalculation",
  async (data) => {
    try {
      const response = await API.put(`/calculation/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateLocation = createAsyncThunk(
  "calculation/updateLocation",
  async (data) => {
    try {
      const response = await API.put(`/update-location-data/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateProfile = createAsyncThunk(
  "calculation/update-consumption-profile-data",
  async (data) => {
    try {
      const response = await API.put(
        `/update-consumption-profile-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateConsumption = createAsyncThunk(
  "calculation/updateConsumption",
  async (data) => {
    try {
      const response = await API.put(
        `/update-consumption-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateGenerator = createAsyncThunk(
  "calculation/updateGenerator",
  async (data) => {
    try {
      const response = await API.put(`/update-generator-data/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateStorageSystem = createAsyncThunk(
  "calculation/updateStorageSystem",
  async (data) => {
    try {
      const response = await API.put(
        `/update-storage-system-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updatePurchase = createAsyncThunk(
  "calculation/updatePurchase",
  async (data) => {
    try {
      const response = await API.put(`/update-purchase-data/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

// Date varify Time of purchase
export const updatePurchaseTimofPurchase = createAsyncThunk(
  "calculation/updatePurchaseTimofPurchase",
  async (payload) => {
    try {
      const response = await API.get(
        `/data-exists/${payload.id}?time_of_purchase=${payload.time_of_purchase}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateOperatingCost = createAsyncThunk(
  "calculation/updateOperatingCost",
  async (data) => {
    try {
      const response = await API.put(
        `/update-operating-cost-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateRequirements = createAsyncThunk(
  "calculation/updateRequirements",
  async (data) => {
    try {
      const response = await API.put(
        `/update-requirements-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateTax = createAsyncThunk(
  "calculation/updateTax",
  async (data) => {
    try {
      const response = await API.put(`/update-tax-data/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteCalculation = createAsyncThunk(
  "calculation/deleteCalculation",
  async (id) => {
    try {
      const response = await API.delete(`/calculation/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

// Calculation module APIs
export const getDefaultsDropdowns = createAsyncThunk(
  "calculation/getDefaultsDropdowns",
  async (key) => {
    try {
      const response = await API.get(`/get-defaults-dropdowns?tab=${key}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getManufacturersListing = createAsyncThunk(
  "calculation/getManufacturersListing",
  async (calculation_id) => {
    try {
      const response = await API.get(
        `/get-manufacturers-listing/${calculation_id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getModelsListing = createAsyncThunk(
  "calculation/getModelsListing",
  async (data) => {
    try {
      const response = await API.get(
        `/get-models-listing/${data.id}/${data.manufacturer}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getManufacturerDetails = createAsyncThunk(
  "calculation/getManufacturerDetails",
  async (data) => {
    try {
      const response = await API.post(`/get-manufacturer-details`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCalculationValues = createAsyncThunk(
  "calculation/getCalculationValues",
  async (data) => {
    try {
      const response = await API.get(
        `/get-calculation-values/${data.id}?tab=${data.tab}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getConsumptionProfilesDropdown = createAsyncThunk(
  "calculation/getConsumptionProfilesDropdown",
  async (id) => {
    try {
      const response = await API.get(`/consumption-profiles-dropdown/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getConsumptionChartdata = createAsyncThunk(
  "calculation/getConsumptionChartdata",
  async (id) => {
    try {
      const response = await API.get(`/get-consumption-chart-data/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const individualReportGenerate = createAsyncThunk(
  "calculation/individualReportGenerate",
  async (data) => {
    console.log(data);
    try {
      const response = await API.get(`/${data.apiName}/${data.id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const verbrauchReportGenerate = createAsyncThunk(
  "calculation/verbrauchReportGenerate",
  async (data) => {
    try {
      const response = await API.get(`/verbrauch-report/${data.id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getConsumptionProfileData = createAsyncThunk(
  "calculation/getConsumptionProfileData",
  async (data) => {
    try {
      const response = await API.get(`/consumption-profile/${data.id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateConsumptionProfileData = createAsyncThunk(
  "calculation/updateConsumptionProfileData",
  async (data) => {
    try {
      const response = await API.post(`/consumption-profile/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getConsumptionProfileCharts = createAsyncThunk(
  "calculation/getConsumptionProfileCharts",
  async (id) => {
    try {
      const response = await API.get(`/consumption-profile-charts/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getDropdownConsumptionCharts = createAsyncThunk(
  "calculation/getDropdownConsumptionCharts",
  async (data) => {
    try {
      const response = await API.post(
        `/consumption-profile-charts/${data.calculation_id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateConsumptionChartdata = createAsyncThunk(
  "calculation/updateConsumptionChartdata",
  async (data) => {
    try {
      const response = await API.put(
        `/update-consumption-chart-data/${data.id}`,
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const mergeReportGenerate = createAsyncThunk(
  "calculation/mergeReportGenerate",
  async (data) => {
    try {
      const response = await API.post(`/mappe-report/${data.id}`, data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getIrridation = createAsyncThunk(
  "calculation/getIrridation",
  async (id) => {
    try {
      const response = await API.get(`/get-irradation/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const uploadCSV = createAsyncThunk(
  "calculation/uploadCSV",
  async (data) => {
    try {
      const response = await API.post(
        `/consumption-upload-csv/${data.id}`,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateVerbrauchJsonCount = createAsyncThunk(
  "standard/updateVerbrauchJsonCount",
  async (data) => {
    return data;
  }
);
export const activePvSystemTour = createAsyncThunk(
  "standard/activePvSystemTour",
  async (data) => {
    return data;
  }
);

export const calculationSlice = createSlice({
  name: "calculation",
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null;
      state.status = null;
      state.updateLocationCompleted = null;
      state.updateProfileCompleted = null;
      state.updateConsumptionCompleted = null;
      state.updatePVSystemFirstTabCompleted = null;
      state.updatePVSystemSecondTabCompleted = null;
      state.updatePVSystemThirdTabCompleted = null;
      state.updatePVSystemFourthTabCompleted = null;
      state.updateFinancingFirstTabCompleted = null;
      state.updateFinancingSecondTabCompleted = null;
      state.makeCalculationDefaultCompleted = null;
      state.verbrauchReportProcessCompleted = null;
      state.pvangaleReportProcessCompleted = null;
      state.updateConsumptionProfileProcessComplete = null;
      state.getCustomerProcessComplete = null;
      state.updateConsumptionProfileProcessComplete = null;
      state.updateCalculationProcess = null;
      state.fetchListing = null;
      state.uploadCSVProcessComplete = null;
      // state.defualtProfileIdFirstTab = null
      state.profileDropdownUserStatus = null;
      state.calculationValues = [];
      state.getIrridationProcessCompleted = null;
      state.fetchCalculationCompleted = null;
      state.consumptionProfileStatus = false;
      state.calculationStatus = true;
    },
    resetCustomer: (state) => {
      state.customer = null;
    },
    resetCalculation: (state) => {
      state.calculation = null;
      // state.calculations = null
      state.lastInsertedID = null;
      state.createCalculationCompleted = null;
      state.copyCalculationCompleted = null;
    },
    resetCustomStates: (state) => {
      state.updateFinancingFirstTabCompleted = null;
      state.updateFinancingSecondTabCompleted = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateConsumptionProfileData.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.updateConsumptionProfileProcessComplete = true;
        SuccessBanner(action.payload.message);
      } else {
        state.updateConsumptionProfileProcessComplete = false;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(getConsumptionProfileData.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.consumption_data = action.payload.data.profile;
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(verbrauchReportGenerate.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.verbrauchReportProcessCompleted = true;
        state.verbrauchReportURL = action.payload.data.report_path;
      } else {
        state.verbrauchReportProcessCompleted = false;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(individualReportGenerate.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.verbrauchReportProcessCompleted = true;
        state.verbrauchReportURL = action.payload.data.report_path;
      } else {
        state.verbrauchReportProcessCompleted = false;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
        state.updateLocationCompleted = true;
      } else {
        ErrorBanner(action.payload.message);
        state.updateLocationCompleted = false;
      }
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
        state.updateProfileCompleted = true;
      } else {
        ErrorBanner(action.payload.message);
        state.updateProfileCompleted = false;
      }
    });
    builder.addCase(updateConsumption.fulfilled, (state, action) => {
      state.updateConsumptionCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateGenerator.fulfilled, (state, action) => {
      state.updatePVSystemFirstTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateStorageSystem.fulfilled, (state, action) => {
      state.updatePVSystemSecondTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        state.errorsFromApiResponse = action.payload.data.errors;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updatePurchase.fulfilled, (state, action) => {
      state.updatePVSystemThirdTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updatePurchaseTimofPurchase.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        // if (
        //   state.calculationValues[0].feed_in_amount &&
        //   state.calculationValues[0].time_of_purchase
        // ) {
        state.calculationValues[0].feed_in_amount =
          action.payload.data.custom_feed_in_tariff;
        state.calculationValues[0].time_of_purchase =
          action?.meta?.arg?.time_of_purchase;
        state.calculationValues[0].feed_in_type =
          action?.meta?.arg?.feed_in_type;
        // }
        // SuccessBanner(action.payload.message);
      } else {
        state.calculationValues[0].feed_in_amount = 0;
        state.calculationValues[0].time_of_purchase =
          action?.meta?.arg?.time_of_purchase;
        state.calculationValues[0].feed_in_type =
          action?.meta?.arg?.feed_in_type;
        // ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateOperatingCost.fulfilled, (state, action) => {
      state.updatePVSystemFourthTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateRequirements.fulfilled, (state, action) => {
      state.updateFinancingFirstTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateTax.fulfilled, (state, action) => {
      state.updateFinancingSecondTabCompleted = true;
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
      } else {
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(getDefaultsDropdowns.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.consumptionDropdowns =
          action.payload.data === null ? [] : action.payload.data;
      } else {
        state.message = action.payload.message;
      }
    });
    builder.addCase(getManufacturersListing.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.manufacturerDropdowns =
          action.payload.data === null ? [] : action.payload.data.results;
      } else {
        state.message = action.payload.message;
      }
    });
    builder.addCase(getModelsListing.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.modelDropdowns =
          action.payload.data === null ? [] : action.payload.data.results;
      } else {
        state.message = action.payload.message;
      }
    });
    builder.addCase(getManufacturerDetails.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.storageFields =
          action.payload.data === null ? [] : action.payload.data.results;
      } else {
        state.message = action.payload.message;
      }
    });
    builder
      .addCase(getCalculationValues.pending, (state, action) => {
        state.fetchCalculationCompleted = null;
      })
      .addCase(getCalculationValues.fulfilled, (state, action) => {
        if (
          action.payload.success === true &&
          parseInt(action.payload.status) === 200
        ) {
          state.calculationValues =
            action.payload.data === null ? [] : action.payload.data.results;
          state.proposalValue =
            action.payload.data.proposal !== undefined
              ? action.payload.data.proposal
              : "";
          state.herstellerExchangeCost =
            action.payload.data === null
              ? []
              : action.payload.data.exchange_cost;
          state.fetchCalculationCompleted = true;
          state.tax_type =
            action.payload.data.tax_type !== undefined
              ? action.payload.data.tax_type
              : null;
          state.mounting_position =
            action.payload.data.mounting_position !== undefined
              ? action.payload.data.mounting_position
              : null;
          state.custom_feed_in_tariff =
            action.payload.data.custom_feed_in_tariff !== undefined
              ? action.payload.data.custom_feed_in_tariff
              : null;
        } else {
          state.fetchCalculationCompleted = true;
          state.message = action.payload.message;
        }
      });
    builder.addCase(getAllCalculation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.calculations =
          action.payload.data === null ? [] : action.payload.data.calculations;
        state.showEmail =
          action.payload.data === null ? [] : action.payload.data.showEmail;
        state.totalCount =
          action.payload.data === null ? [] : action.payload.data.total;
        state.fetchListing = true;
      } else {
        state.message = action.payload.message;
      }
    });
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.customer = action.payload.data.customer;
        state.lat = action.payload.data.lat;
        state.lng = action.payload.data.lng;
        state.irridation_value =
          action.payload.data.reportParameters !== null
            ? action.payload.data.reportParameters.json
            : 0;
        state.getCustomerProcessComplete = true;
      } else {
        state.getCustomerProcessComplete = false;
        state.message = action.payload.message;
        state.customer = null;
        ErrorBanner("Die Kundenadresse ist nicht gültig.");
      }
    });
    builder.addCase(createCalculation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.lastInsertedID = action.payload.data.calculation.id;
        state.createCalculationCompleted = true;
        SuccessBanner(action.payload.message);
      } else if (parseInt(action.payload.status) === 422) {
        state.createCalculationCompleted = false;
        state.message = action.payload.message;
        state.errorsFromApiResponse = action.payload.data.errors;
      } else if (parseInt(action.payload.status) === 404) {
        state.createCalculationCompleted = false;
        state.message = action.payload.message;
        ErrorBanner(action.payload.message);
      } else {
        state.createCalculationCompleted = false;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(copyCalculation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.lastInsertedID = action.payload.data.calculation_id;
        state.copyCalculationCompleted = true;
        SuccessBanner(action.payload.message);
      } else if (parseInt(action.payload.status) === 422) {
        state.message = action.payload.message;
      }
    });
    builder.addCase(makeCalculationDefault.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.makeCalculationDefaultCompleted = true;
        SuccessBanner(action.payload.message);
      } else if (parseInt(action.payload.status) === 422) {
        state.message = action.payload.message;
      }
    });
    builder.addCase(getCalculation.fulfilled, (state, action) => {
      localStorage.removeItem("reportArray");
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.calculation = action.payload.data.calculation;
        state.calculationStatus = true;
        state.consumptionProfileStatus =
          action?.payload?.data?.consumption_profile;
        state.verbrauchJsonCount = action.payload.data.verbrauch_json_count;
      } else {
        state.calculationStatus = false;
        ErrorBanner(action.payload.message);
      }
    });
    builder.addCase(updateCalculation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.updateCalculationProcess = true;
        SuccessBanner(action.payload.message);
      } else if (parseInt(action.payload.status) === 422) {
        state.message = action.payload.message;
        state.errorsFromApiResponse = action.payload.data.errors;
      }
    });
    builder.addCase(deleteCalculation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message);
      } else {
        state.message = action.payload.message;
        state.errorsFromApiResponse = action.payload.data.errors;
      }
    });
    builder.addCase(
      getConsumptionProfilesDropdown.fulfilled,
      (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.status = action.payload.status;
          state.success = action.payload.success;
          state.consumptionProfilesDropdown = action.payload.data.profiles;
          state.defualtProfileId =
            action.payload.data.calculation_profile !== null
              ? action.payload.data.calculation_profile.id
              : null;
          state.defualtProfileIdFirstTab =
            action?.payload?.data.default_profile?.id;
          state.defaultConsumptionData =
            action.payload.data.default_consumption_data;
          state.defaultConsumptionOtherData = {
            electricity_price_development:
              action?.payload?.data?.electricity_price_development,
            anicilary_cost: action.payload?.data?.anicilary_cost,
          };
          state.profileDropdownUserStatus = action.payload.data.userstatus;
        } else {
          state.message = action.payload.message;
        }
      }
    );
    builder.addCase(getConsumptionChartdata.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.getChart1data = action.payload.data.chart1_data;
        state.getChart2data = action.payload.data.chart2_data;
        state.getChart3data = action.payload.data.chart3_data;
        state.wochenede = action.payload.data.wochenede;
        state.werktags = action.payload.data.werktags;
      } else {
        state.message = action.payload.message;
      }
    });

    builder.addCase(getConsumptionProfileCharts.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.data = action.payload.data;

        const verburchChartData = [];
        const AkkufüllstandChartData = [];
        const ErzeugungChartData = [];

        action.payload.data.Stromverbrauch[4].forEach((value) => {
          verburchChartData.push(value.Wert);
        });
        action.payload.data.Akkufuellung[4].forEach((value) => {
          AkkufüllstandChartData.push(value.Wert);
        });
        action.payload.data.PVStrom[4].forEach((value) => {
          ErzeugungChartData.push(value.Wert);
        });

        state.verburchChartData = verburchChartData;
        state.AkkufüllstandChartData = AkkufüllstandChartData;
        state.ErzeugungChartData = ErzeugungChartData;
      } else {
        state.message = action.payload.message;
      }
    });

    builder.addCase(getDropdownConsumptionCharts.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.data = action.payload.data;

        const verburchChartData = [];
        const AkkufüllstandChartData = [];
        const ErzeugungChartData = [];

        action.payload.data.Stromverbrauch[4].forEach((value) => {
          verburchChartData.push(value.Wert);
        });
        action.payload.data.Akkufuellung[4].forEach((value) => {
          AkkufüllstandChartData.push(value.Wert);
        });
        action.payload.data.PVStrom[4].forEach((value) => {
          ErzeugungChartData.push(value.Wert);
        });

        state.verburchChartData = verburchChartData;
        state.AkkufüllstandChartData = AkkufüllstandChartData;
        state.ErzeugungChartData = ErzeugungChartData;
      } else {
        state.message = action.payload.message;
      }
    });

    builder.addCase(updateConsumptionChartdata.fulfilled, (state, action) => {
      if (
        action.payload &&
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
        state.updateProfileCompleted = true;
      } else {
        state.updateProfileCompleted = false;
        ErrorBanner(
          action.payload
            ? action.payload.message
            : "Etwas ist schief gelaufen. Bitte versuche es erneut"
        );
      }
    });

    builder.addCase(mergeReportGenerate.fulfilled, (state, action) => {
      localStorage.removeItem("reportArray");
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.verbrauchReportProcessCompleted = true;
        state.verbrauchReportURL = action.payload.data;
      } else {
        state.verbrauchReportProcessCompleted = false;
        ErrorBanner(action.payload.message);
      }
    });

    builder.addCase(getIrridation.fulfilled, (state, action) => {
      if (
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        state.success = action.payload.success;
        state.getIrridationProcessCompleted = true;
      } else {
        state.message = action.payload.message;
        state.getIrridationProcessCompleted = true;
      }
    });

    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      if (
        action.payload &&
        action.payload.success === true &&
        parseInt(action.payload.status) === 200
      ) {
        SuccessBanner(action.payload.message);
        state.uploadCSVProcessComplete = true;
      } else {
        state.uploadCSVProcessComplete = false;
        ErrorBanner(
          action.payload
            ? action.payload.message
            : "Etwas ist schief gelaufen. Bitte versuche es erneut"
        );
      }
    });

    builder.addCase(updateVerbrauchJsonCount.fulfilled, (state, action) => {
      state.verbrauchJsonCount = action.payload;
    });

    builder.addCase(activePvSystemTour.fulfilled, (state, action) => {
      state.showPvSystemTour = action.payload;
    });
  },
});
export const {
  resetAllState,
  resetCustomer,
  resetCalculation,
  resetCustomStates,
} = calculationSlice.actions;
export default calculationSlice.reducer;
