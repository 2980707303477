import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  consumptionProfile: [],
  AddOrUpdateConsumptionProfileCompleted: null,
  DeleteConsumptionProfileCompleted: null,
  AddOrDeleteActionFromConsumptionProfile: null
}

export const ListConsumptionProfile = createAsyncThunk(
  'administration/ListConsumptionProfile',
  async (id) => {
    try {
      const response = await API.get(`/administration/consumption-profiles/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const AddOrUpdateConsumptionProfile = createAsyncThunk(
  'administration/AddOrUpdateConsumptionProfile',
  async (data) => {
    try {
      const response = await API.post(`/administration/consumption-profile`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const DeleteConsumptionProfile = createAsyncThunk(
  'administration/DeleteConsumptionProfile',
  async (id) => {
    try {
      const response = await API.delete(`/administration/consumption-profile/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const ConsumptionSlice = createSlice({
  name: 'consumption',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.AddOrUpdateConsumptionProfileCompleted = null
      state.DeleteConsumptionProfileCompleted = null
      state.AddOrDeleteActionFromConsumptionProfile = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ListConsumptionProfile.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.consumptionProfile = action.payload.data === null ? [] : action.payload.data.profiles
      } else {
        state.message = action.payload.data === null ? [] : action.payload.message
      }
    });
    builder.addCase(AddOrUpdateConsumptionProfile.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.AddOrUpdateConsumptionProfileCompleted = true
        state.AddOrDeleteActionFromConsumptionProfile = true
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.AddOrUpdateConsumptionProfileCompleted = false
      }
    });
    builder.addCase(DeleteConsumptionProfile.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.DeleteConsumptionProfileCompleted = true
        state.AddOrDeleteActionFromConsumptionProfile = true
      } else if (parseInt(action.payload.status) === 422) {
        ErrorBanner(action.payload.message)
        state.DeleteConsumptionProfileCompleted = false
      }
    });
  },
})

export const { resetAllState } = ConsumptionSlice.actions;
export default ConsumptionSlice.reducer