import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RegisterModal from "../register-modal/RegisterModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, FormGroup, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import HideP from "../../assets/images/PasswordHide.svg";
import ShowP from "../../assets/images/PasswordVis.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {
  loginAsync,
  resetAllState,
  selectMessage,
  selectSuccess,
} from "./LoginModalSlice";
import { fetchUserInfo } from "../register-modal/RegisterModalSlice";

const LoginModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerShow, setRegisterShow] = useState(false);
  const RegisterhandleClose = () => setRegisterShow(false);
  const [resMessage, setResMessage] = useState(false);
  const success = useSelector(selectSuccess);
  const message = useSelector(selectMessage);
  const [Loader, setLoader] = useState(false);
  const [showAlreadyLoggedInBox, setShowAlreadyLoggedInBox] = useState(false);
  const [passwordShowHide, setPasswordShowHide] = useState("password");
  const [googleCaptchaVerified, setGoogleCaptchaVerified] = useState(false);
  const alreadyLoggedIn = useSelector((state) => state.login.alreadyLoggedIn);
  const tempToken = useSelector((state) => state.login.tempToken);
  const tempUserid = useSelector((state) => state.login.tempUserid);
  const loginSuccess = useSelector((state) => state.login.loginSuccess);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const loggedInUserSubscriptionInfo = useSelector(
    (state) => state.login.loggedInUserSubscriptionInfo
  );
  const agree_TaC = useSelector((state) => state.login.agree_TaC);
  // eslint-disable-next-line
  const demo_expired = useSelector((state) => state.login.demo_expired);

  useEffect(() => {
    if (
      window.location.pathname === "/registration-success" &&
      (localStorage.getItem("user") !== null ||
        localStorage.getItem("user") === undefined)
    ) {
      window.location = "/";
    }
    // eslint-disable-next-line
  }, []);

  const googleReCaptchaOnChange = (value) => {
    setGoogleCaptchaVerified(value !== null ? true : false);
  };

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    setFieldValue,
    resetForm,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      closePreviousSession: "",
      tempToken: "",
      tempUserid: "",
      remember_me: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
        .required("Das Feld E-Mail ist erforderlich."),
      password: Yup.string().required("Das Feld Passwort ist erforderlich."),
    }),
    onSubmit: async (values, action) => {
      values = {
        ...values,
        tempToken: tempToken,
        tempUserid: tempUserid,
      };
      setLoader(true);
      dispatch(loginAsync(values));
    },
  });
  useEffect(() => {
    if (alreadyLoggedIn === true) {
      setResMessage(true);
      setLoader(false);
      setShowAlreadyLoggedInBox(true);
      // values.password = ''
      values.closePreviousSession = "YES";
    }
    if (success === false || success === true) {
      setResMessage(true);
      setLoader(false);
      dispatch(resetAllState());
    }
    if (agree_TaC === false) {
      dispatch(fetchUserInfo(loggedInUserInfo.id));
      props.handleLoginClose(false);
      resetForm();
      setShowAlreadyLoggedInBox(false);
      dispatch(resetAllState());
      const login_expiration_time = new Date();
      if (values.remember_me === true) {
        login_expiration_time.setHours(login_expiration_time.getHours() + 48);
      } else if (values.remember_me === false) {
        login_expiration_time.setHours(login_expiration_time.getHours() + 24);
      }
      localStorage.setItem("login_expiration_time", login_expiration_time);
      setTimeout(() => {
        navigate("/terms-of-use/" + loggedInUserInfo.id);
      }, 600);
    }
    if (loginSuccess === true && success === true) {
      const login_expiration_time = new Date();
      if (values.remember_me === true) {
        login_expiration_time.setHours(login_expiration_time.getHours() + 48);
      } else if (values.remember_me === false) {
        login_expiration_time.setHours(login_expiration_time.getHours() + 24);
      }
      localStorage.setItem("login_expiration_time", login_expiration_time);
      // eslint-disable-next-line
      let activeSubscriptionCount = 0;
      loggedInUserSubscriptionInfo.map((subscription) => {
        if (
          subscription.status === "Aktiv" ||
          subscription.status === "Gekündigt"
        ) {
          activeSubscriptionCount = 1;
        }
        return true;
      });

      console.log(parseInt(loggedInUserInfo.subscription_enabled));
      if (parseInt(loggedInUserInfo.subscription_enabled) === 1) {
        navigate("/demo-expired");
      } else if (parseInt(loggedInUserInfo.demo_enabled) === 1) {
        navigate("/offer");
      } else {
        navigate("/");
      }

      // if (demo_expired === true && parseInt(loggedInUserInfo.demo_enabled) === 1) {
      //     navigate('/offer')
      // } else if ((demo_expired === false || demo_expired === null) && parseInt(loggedInUserInfo.subscription_enabled) === 1) {
      //     navigate('/demo-expired')
      // } else if ((demo_expired === false || demo_expired === null) && parseInt(loggedInUserInfo.user_enabled) === 1 && parseInt(loggedInUserInfo.manager_enabled) === 1 && parseInt(loggedInUserInfo.admin_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/')
      // } else if ((demo_expired === false || demo_expired === null) && parseInt(loggedInUserInfo.user_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/offer')
      // } else if (demo_expired === true && parseInt(loggedInUserInfo.user_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/')
      // } else if ((demo_expired === false || demo_expired === null) && parseInt(loggedInUserInfo.user_enabled) === 1 && parseInt(loggedInUserInfo.manager_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/offer')
      // }

      // if (parseInt(loggedInUserInfo.subscription_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/demo-expired')
      // } else if (parseInt(loggedInUserInfo.subscription_enabled) === 1) {
      //     navigate('/demo-expired')
      // } else if (demo_expired === true && parseInt(loggedInUserInfo.demo_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/demo-expired')
      // } else if (demo_expired === false && parseInt(loggedInUserInfo.demo_enabled) === 1 && activeSubscriptionCount === 0) {
      //     navigate('/offer')
      // } else if (parseInt(loggedInUserInfo.terms_conditions) === 1 && parseInt(loggedInUserInfo.demo_enabled) === 1 && activeSubscriptionCount === 1){
      //     navigate('/offer')
      // } else if (parseInt(loggedInUserInfo.terms_conditions) === 1 && parseInt(loggedInUserInfo.subscription_id) === 1) {
      //     navigate('/customers')
      // } else {
      //     navigate('/customers')
      // }

      dispatch(resetAllState());
    }
    // eslint-disable-next-line
  }, [dispatch, success, alreadyLoggedIn, loginSuccess, agree_TaC]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <FormGroup
              className="custom-form-control"
              controlId="formBasicEmail"
            >
              <label htmlFor="email">E-Mail</label>
              <input
                type="email"
                name="email"
                readOnly={showAlreadyLoggedInBox}
                placeholder=" "
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email ? (
                <span className="error-massage">{errors.email}</span>
              ) : (
                <span className="error-massage">&nbsp;</span>
              )}
            </FormGroup>
          </Col>
          <Col lg={12}>
            <FormGroup
              className="mb-0 custom-form-control"
              controlId="formBasicEmail"
            >
              <label htmlFor="email">Passwort</label>
              <div className="password-feild">
                <input
                  type={passwordShowHide}
                  name="password"
                  placeholder=" "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <Link
                  className="custom-link"
                  onClick={() => {
                    if (passwordShowHide === "text") {
                      setPasswordShowHide("password");
                    } else if (passwordShowHide === "password") {
                      setPasswordShowHide("text");
                    }
                  }}
                >
                  {passwordShowHide === "text" ? (
                    <img src={HideP} className="hide-password" alt="" />
                  ) : (
                    <img src={ShowP} className="show-password" alt="" />
                  )}
                </Link>
              </div>
              {/* { errors.password && touched.password ? <span className="error-massage">{errors.password}</span> : <span className="error-massage">&nbsp;</span> } */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-end">
            <Link
              className="custom-link"
              to="/forgot-password"
              onClick={() => props.handleLoginClose(false)}
            >
              Passwort vergessen?
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-24">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
              onChange={googleReCaptchaOnChange}
            />
            <span className="error-massage">
              {errors.captcha && touched.captcha && errors.captcha}
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {/* <label className="custom-checkbox mb-0">
             
              
            </label> */}
            <label class="custom-checkbox">
              <input
                type="checkbox"
                name="remember_me"
                onChange={(e) => {
                  setFieldValue("remember_me", e.currentTarget.checked);
                }}
              />
              <span class="checkmark"></span>
              <span class="checkmark-title">Angemeldet bleiben</span>
            </label>
          </Col>
        </Row>
        {resMessage === true ? (
          <Row>
            <Col lg={12}>
              <span style={{marginBottom:'16px'}} className="error-massage">{message}</span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={12}>
              <span style={{marginBottom:'16px'}} className="error-massage">&nbsp;</span>
            </Col>
          </Row>
        )}
        {showAlreadyLoggedInBox === false ? (
          <Row className="align-items-center">
            <Col sm={4}>
              <Button
                disabled={!(isValid && dirty && googleCaptchaVerified)}
                className="btn btn-primary align-items-center"
                type="submit"
              >
                Anmelden{" "}
                {Loader ? (
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : null}
              </Button>
            </Col>
            <Col sm={8} className="d-flex justify-content-sm-end ps-md-0">
              <div className="dont-have-account">
                <p>Haben Sie noch keinen Account?</p>
                <Button
                  onClick={() => {
                    props.handleRegisterShow(true);
                    props.handleLoginClose(false);
                  }}
                  className="custom-link"
                >
                  Registrieren
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <input
              type="hidden"
              name="closePreviousSession"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.closePreviousSession}
            />
            <Col lg={12}>
              <Button
                disabled={!(isValid && dirty && googleCaptchaVerified)}
                className="btn btn-primary"
                type="submit"
              >
                Anmelden und bisherigen Zugang schließen{" "}
                {Loader ? (
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : null}
              </Button>
            </Col>
          </Row>
        )}
      </form>
      <RegisterModal
        registerShow={registerShow}
        RegisterhandleClose={RegisterhandleClose}
      />
    </>
  );
};

export default LoginModal;
