import React, { Suspense } from 'react'
import { CutomerRoutes } from './routes/customer.route'
import { Route, Routes, Navigate } from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

export const Layout = () => {
    function displaypages() {
        return (
            <Suspense fallback={<div className="container"><div className="row"><div className="col" style={{ marginTop: `20%` }}><div className="d-flex justify-content-center"><div className="spinner-border" role="status"><span className="sr-only"></span></div></div></div></div></div>}>
                <Routes>
                    {CutomerRoutes.map((route, index) => {
                        return (
                            route.element && (
                                <Route key={index} exact={route.exact} path={route.path} name={route.name} element={<route.element />} />
                            )
                        )
                    })}
                    <Route path="*" element={< Navigate to={{ pathname: '/' }} replace />} />
                </Routes>
            </Suspense>
        )
    }
    return (
        <>
            <Header />
            <div>
                {displaypages()}
            </div>
            <Footer />
        </>
    )
}
