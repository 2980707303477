import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/api';
import { ErrorBanner, SuccessBanner } from "../../components/banner/banner";

const initialState = {
  status: null,
  success: null,
  createCustomerCompleted: null,
  updateCustomerCompleted: null,
  message: null,
  customers: [],
  customer: null,
  calculations: [],
  totalCount: 0,
  errorsFromApiResponse: null,
  lastAddedCustomerId: null,
  users: [],
  moveCustomerCompleted: null,
  deleteCustomerCompleted: null,
  fetchListing: null,
  verifyAddressStatus: null,
  verifyAddressStatusTwo: null,
  verifyAddressStatusMessage: null
}

export const getUsers = createAsyncThunk(
  'customers/getUsers',
  async (data) => {
    try {
      const response = await API.post(`/get-users`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const verifyAddress = createAsyncThunk(
  'customers/verifyAddress',
  async (data) => {
    try {
      const response = await API.post(`/verify-address`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const moveCustomer = createAsyncThunk(
  'customers/moveCustomer',
  async ({ id, email }) => {
    try {
      const response = await API.put(`/move-customer/${id}`, { user: email })
      return response
    } catch (err) {
      return err
    }
  }
)

export const customersAsync = createAsyncThunk(
  'customers/getCustomers',
  async (data) => {
    try {
      const response = await API.post(`/customer-list`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (data) => {
    try {
      const response = await API.post(`/customer`, data)
      return response
    } catch (err) {
      return err
    }
  }
)


export const editcustomer = createAsyncThunk(
  'customers/editCustomer',
  async (data) => {
    try {
      const response = await API.get(`/customer/${data.id}/edit?size=10&page=${data.page}`)
      return response

    } catch (err) {
      return err
    }
  }
)

export const updatecustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (data) => {
    try {
      const response = await API.put(`/customer/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const deletecustomer = createAsyncThunk(
  'customers/deletecustomer',
  async (id) => {
    try {
      const response = await API.delete(`/customer/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null
      state.status = null
      state.success = null
      state.createCustomerCompleted = null
      state.moveCustomerCompleted = null
      state.updateCustomerCompleted = null
      state.deleteCustomerCompleted = null
      state.fetchListing = null
      state.verifyAddressStatusTwo = null
      state.getCustomer = true
    },
    resetCustomer: (state) => {
      state.customer = null
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.users = action.payload.data === null ? [] : action.payload.data.users
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });

    builder.addCase(verifyAddress.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.verifyAddressStatus = true
        state.verifyAddressStatusTwo = true
      } else {
        state.verifyAddressStatus = false
        state.verifyAddressStatusTwo = false
        state.verifyAddressStatusMessage = action.payload.message
      }
    });

    builder.addCase(moveCustomer.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.moveCustomerCompleted = true
      } else {
        state.moveCustomerCompleted = false
      }
    });
    builder.addCase(deletecustomer.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.deleteCustomerCompleted = true
      } else {
        ErrorBanner(action.payload.message)
        state.deleteCustomerCompleted = false
      }
    });
    builder.addCase(customersAsync.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.customers = action.payload.data === null ? [] : action.payload.data.customers
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        // ErrorBanner(action.payload.message)
        state.message = action.payload.message
      }
    });
    builder
      .addCase(createCustomer.pending, (state) => {
        state.errorsFromApiResponse = null
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.createCustomerCompleted = true
          state.lastAddedCustomerId = action.payload.data.customer.id
          SuccessBanner(action.payload.message)
        } else if (parseInt(action.payload.status) === 422) {
          ErrorBanner(action.payload.message)
          state.message = action.payload.message
          state.errorsFromApiResponse = action.payload.data.errors
          state.createCustomerCompleted = false
        }
      });
    builder
      .addCase(editcustomer.pending, (state) => { })
      .addCase(editcustomer.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.getCustomer = true
          state.status = action.payload.status
          state.success = action.payload.success
          state.customer = action.payload.data.customer
          state.calculations = action.payload.data.calculations === null ? [] : action.payload.data.calculations
          state.totalCount = action.payload.data.calculations === null ? [] : action.payload.data.calculations.total
        } else {
          state.getCustomer = false
          state.message = action.payload.message
          ErrorBanner(action.payload.message)
        }
      });
    builder
      .addCase(updatecustomer.pending, (state) => {
        state.errorsFromApiResponse = null
      })
      .addCase(updatecustomer.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.status = action.payload.status
          state.success = action.payload.success
          state.updateCustomerCompleted = true
          SuccessBanner(action.payload.message)
        } else {
          ErrorBanner(action.payload.message)
          state.message = action.payload.message
          state.errorsFromApiResponse = action.payload.data.errors
          state.updateCustomerCompleted = false
        }
      });
  },
})
export const { resetAllState, resetCustomer } = customerSlice.actions;
export default customerSlice.reducer