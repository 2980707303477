import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../../services/api';
import { ErrorBanner, SuccessBanner } from "../../../components/banner/banner";

const initialState = {
  status: null,
  success: null,
  createOrganisationCompleted: null,
  updateOrganisationCompleted: null,
  deleteOrganisationCompleted: null,
  createOrganisationId: null,
  message: null,
  organisations: [],
  org: null,
  totalCount: 0,
  errorsFromApiResponse: null,
  lastAddedOrganisationId: null,
  fetchListing: null,
  getOrganisationResponse: true,
}

export const getOrganisations = createAsyncThunk(
  'organisations/getOrganisations',
  async (data) => {
    try {
      const response = await API.post(`admin/organizations`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const getOrganisationList = createAsyncThunk(
  'organisations/getOrganisationList',
  async () => {
    try {
      const response = await API.get(`/admin/organizations-dropdown`)
      return response
    } catch (err) {
      return err
    }
  }
)

export const createOrganisation = createAsyncThunk(
  'organisations/createOrganisation',
  async (data) => {
    try {
      const response = await API.post(`/admin/organization`, data)
      return response
    } catch (err) {
      return err
    }
  }
)
export const deleteOrganisation = createAsyncThunk(
  'organisations/deleteOrganisation',
  async (id) => {
    try {
      const response = await API.delete(`/admin/organization/${id}`)
      return response
    } catch (err) {
      return err
    }
  }
)


export const editOrganisation = createAsyncThunk(
  'organisations/editOrganisation',
  async (id) => {
    try {
      const response = await API.get(`/admin/organization/${id}`)
      return response

    } catch (err) {
      return err
    }
  }
)

export const updateOrganisation = createAsyncThunk(
  'organisations/updateOrganisation',
  async (data) => {
    try {
      const response = await API.put(`/admin/organization/${data.id}`, data)
      return response
    } catch (err) {
      return err
    }
  }
)

export const OrganisationSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    resetAllState: (state) => {
      state.message = null
      state.status = null
      state.success = null
      state.createOrganisationCompleted = null
      state.updateOrganisationCompleted = null
      state.deleteOrganisationCompleted = null
      state.createOrganisationId = null
      state.fetchListing = null
    },
    resetOrganisation: (state) => {
      state.organisations = null
      state.org = null
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      state.org = null
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        //console.log(action.payload.data.companies)
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.organisations = action.payload.data === null ? [] : action.payload.data.companies
        state.totalCount = action.payload.data === null ? [] : action.payload.data.total
        state.fetchListing = true
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(getOrganisationList.fulfilled, (state, action) => {
      if (action.payload.success === true && parseInt(action.payload.status) === 200) {
        state.status = action.payload.status;
        state.success = action.payload.success;
        state.organisations = action.payload.data === null ? [] : action.payload.data.companies
      } else {
        state.message = action.payload.message
      }
    });
    builder.addCase(createOrganisation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        state.createOrganisationCompleted = true
        state.createOrganisationId = action.payload.data.company.id
        SuccessBanner(action.payload.message)
      } else if (parseInt(action.payload.status) === 422) {
        state.createOrganisationCompleted = false
        state.message = action.payload.message
        state.errorsFromApiResponse = action.payload.data.errors
        ErrorBanner(action.payload.data.errors.name[0])
      }
    });
    builder.addCase(deleteOrganisation.fulfilled, (state, action) => {
      if (parseInt(action.payload.status) === 200) {
        SuccessBanner(action.payload.message)
        state.deleteOrganisationCompleted = true
      } else {
        ErrorBanner(action.payload.message)
        state.deleteOrganisationCompleted = false
      }
    });
    builder
      .addCase(editOrganisation.pending, (state) => { })
      .addCase(editOrganisation.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.status = action.payload.status
          state.success = action.payload.success
          state.org = action.payload.data.company === null ? [] : action.payload.data.company
        } else {
          state.getOrganisationResponse = false;
          ErrorBanner(action.payload.message)
          state.message = action.payload.message
        }
      });
    builder
      .addCase(updateOrganisation.pending, (state) => { })
      .addCase(updateOrganisation.fulfilled, (state, action) => {
        if (parseInt(action.payload.status) === 200) {
          state.status = action.payload.status
          state.success = action.payload.success
          state.updateOrganisationCompleted = true
          SuccessBanner(action.payload.message)
        } else {
          ErrorBanner(action.payload.message)
          state.updateCustomerCompleted = false
          state.message = action.payload.message
        }
      });
  },
})
export const { resetAllState, resetOrganisation } = OrganisationSlice.actions;
export default OrganisationSlice.reducer